import { createGlobalStyle, css } from 'styled-components';
import { ScreenWidthType } from '../../interfaces';
import { getTopNavHeight } from '../../utils';

interface GlobalProps {
  screenWidthType: ScreenWidthType;
  showGameTopBar: boolean;
}

const setTop = (props: GlobalProps) => css`
  top: ${getTopNavHeight(props.screenWidthType, props.showGameTopBar)}px !important;
`;

// stylelint-disable order/order, selector-max-id, max-nesting-depth
const GlobalTopNavigationStyle = createGlobalStyle<GlobalProps>`

  body {
    ${(props) => css`
      padding-top: ${getTopNavHeight(props.screenWidthType, props.showGameTopBar)}px;
    `}

    @media only screen and (max-width: 767px) {

      iframe#webWidget {
        top: auto !important;
      }
    }
  }

  .navbar {
    margin-bottom: 0 !important;
    ${(props) => props.screenWidthType !== ScreenWidthType.LARGE && css`
      margin-top: -5px !important;
    `}
    ${(props) => props.screenWidthType === ScreenWidthType.LARGE && css`
      margin-top: 40px !important;
    `}
  }

  .menu-bingo-invite .menu-label,
  .menu-bingo-invite .navbar-text,
  #menu-all-games-6,
  .navbar-bottom-border,
  .navbar-brand {
    display: none !important;
  }

  body:not(.game-open):not(.platform-app) .top-navigation .menu-user-bonus.hidden-mxs-no-game {
    display: block !important;
  }

  .modal-window.modal-maximized {
    ${(props) => setTop(props)}
  }

  .menu-user-bonus.open,
  .menu-cashier.open,
  .menu-quick-deposit.open,
  .menu-user-account.open {
    display: block !important;
  }

  @media (max-width: 399px) {

    .menu-bingo-invite.hidden ~ .menu-user-bonus.open {
      display: block !important;
    }
  }

  @media (min-width: 768px) {

    .menu-user-bonus,
    .menu-cashier,
    .menu-quick-deposit,
    .menu-user-account {
      position: static !important;
    }

    .dropdown-menu-my-account {
      right: 0 !important;
    }


    @media (min-width: 1560px) {

      .dropdown-menu-my-account {
        right: -45px !important;
      }
    }

    @media (min-width: 1650px) {

      .dropdown-menu-my-account {
        right: -90px !important;
      }
    }

    .dropdown-menu-bonus {
      right: 10px !important;
    }

    .dropdown-menu-balance,
    .dropdown-menu-deposit {
      right: 95px !important;
    }
  }

  @media(max-width: 991px) {

    .modal-window.modal-full-md {
      ${(props) => setTop(props)}
    }
  }

  @media (max-width: 767px) {

    .navbar-nav li.dropdown.open > .dropdown-menu,
    .modal-window.modal-full-sm {
      ${(props) => setTop(props)}
    }
  }

  @media(max-width: 479px) {

    .modal-window.modal-full-mxs {
      ${(props) => setTop(props)}
    }
  }

  /* sports overrides */
  #sports-container {

    .newsfeed,
    .navigation {
      ${(props) => css`
        padding-top: ${getTopNavHeight(props.screenWidthType, props.showGameTopBar)}px !important;
      `}
    }
  }

  body.page-sports .page-content.page-content-left .page-sidebar,
  .sb-mobile-filter-nav,
  .sportsbook-nav,
  .sb-close-sidemenu-mobile {
    ${(props) => setTop(props)}
  }

  .top-navigation {
    padding: 0 !important;
  }

  .navbar,
  .navbar-header > a,
  .navbar-nav > li .navbar-text,
  .navbar-nav > li,
  .navbar-nav > li > a,
  .navbar-nav > li.no-pad > a,
  .navbar-nav > li:after,
  .navbar-nav > li:before,
  .navbar > .container,
  .navbar > .container > .navbar-header,
  .top-navigation > .navbar.main-navbar {
    margin: 0 !important;
    ${(props) => css`
      height: ${getTopNavHeight(props.screenWidthType, false)}px !important;
    `}
  }

  .chat-content {
    ${(props) => setTop(props)}
  }

  .navbar > .container {
    max-width: 1440px !important;
    margin: auto !important;
    position: relative !important;

    @media (min-width: 1200px) {

      & {
        width: auto;
      }
    }
  }

  .top-navigation > .navbar.main-navbar .navbar-bottom-border {
    height: 0;
  }

  .view-type-sports.game-open .chat-content {
    z-index: 305 !important;
  }

  .view-type-sports.game-open header.top-navigation {
    z-index: 306;
  }
`;

export default GlobalTopNavigationStyle;
