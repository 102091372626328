import styled, { css } from 'styled-components';

interface AsideProps {
  open?: boolean;
}

const Aside = styled.aside<AsideProps>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;

  ${(props) => !props.open && css`
    transform: translateX(-100%);
    transition: background-color 200ms ease, transform 120ms;
  `};

  ${(props) => props.open && css`
    transform: translateX(0);
    transition: background-color 200ms ease, transform 200ms;
  `};

  background-color: ${(props) => props.theme.themed.background.primary};
  max-width: 370px;
  overflow: auto;

  /* 30px for sidenav to have backdrop visible from right */
  width: calc(100% - 30px);

  z-index: 6000;

  .modal-opened:not(.blocking-modal-opened) & {
    z-index: 10000;
  }
`;

export default Aside;
