import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  components: {
    buttons: {
      base: {
        color: '#ffffff',
        background: '#5a5a5a',
      },
      primary: {
        color: '#ffffff',
        background: '#5a5a5a',
      },
    },
    betslip: {
      color: '#5a5a5a',
      disabled: '#5a5a5a',
    },
    progress: {
      color: '#ffffff',
      background: '#ffffff',
    },
  },
  plain: {
    background: {
      primary: '#ffffff',
      secondary: '#ffffff',
      backdrop: '#ffffff',
      badge: '#ffffff',
      chat: '#ffffff',
      linkHover: '#ffffff',
    },
    foreground: {
      base: '#5a5a5a',
      dividers: '#ffffff',
      linkColor: '#5a5a5a',
      linkHover: '#5a5a5a',
      linkActive: '#5a5a5a',
    },
  },
  themed: {
    background: {
      primary: '#5a5a5a',
      secondary: '#333333',
      backdrop: '#5a5a5a',
      badge: '#5a5a5a',
      chat: '#5a5a5a',
      linkHover: '#5a5a5a',
    },
    foreground: {
      base: '#ffffff',
      dividers: '#5a5a5a',
      linkColor: '#ffffff',
      linkHover: '#ffffff',
      linkActive: '#ffffff',
    },
  },
};

export default theme;
