import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPlayerLoyaltyBar } from '../store/actions/player';
import { RootState } from '../interfaces';
import { Loyalty } from '../interfaces/player';

export default (): void => {
  const [initialized, setInitialized] = useState(false);
  const dispatch = useDispatch();
  const { rsiApiLoaded, viewArgsLoaded } = useSelector((state: RootState) => state.application);
  const { loggedIn } = useSelector((state: RootState) => state.player);
  // update from local storage
  const updateLoyaltyLevels = (): void => {
    try {
      const { cage } = viewArgs.mainConf;
      const loyalty = sessionStorage.getItem(`loyaltyBar-${cage}`);
      const loyaltyObject = loyalty ? JSON.parse(loyalty) : null;

      dispatch(setPlayerLoyaltyBar(loyaltyObject));
    } catch (e) {
      dispatch(setPlayerLoyaltyBar(null));
    }
  };

  // listen to loyalty change event
  const initializeLoyaltyLevels = (): void => {
    rsiApi.on(rsiApi.getEvent('LOYALTY_BAR_UPDATE'), (loyaltyChange: Loyalty) => {
      dispatch(setPlayerLoyaltyBar(loyaltyChange));
    });

    setInitialized(true);
  };

  useEffect(() => {
    if (!rsiApiLoaded || !viewArgsLoaded) {
      return;
    }

    initializeLoyaltyLevels();
    // eslint-disable-next-line
  }, [viewArgsLoaded, rsiApiLoaded]);

  useEffect(() => {
    if (!initialized) {
      return;
    }

    updateLoyaltyLevels();
    // eslint-disable-next-line
  }, [loggedIn, initialized]);
};
