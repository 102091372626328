import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoints } from '../../constants';

export interface IconProps {
  src: string;
  iconClick?: () => void;
  width?: number | string;
  height?: number | string;
  maxWidth?: number;
  maxHeight?: number;
  xsScreenMaxWidth?: number | null;
  alt?: string;
  className?: string;
  imageDataTestId?: string;
}

const Container = styled.div`
  display: flex;
  position: relative;
`;

const ClickContainer = styled.div`
  position: absolute;
  top: -.2em;
  left: -.2em;
  right: -.2em;
  bottom: -.2em;
  cursor: pointer;
`;

const handleSizeValue = (value?: string | number) => {
  let size = 'auto';
  if (value) {
    if (typeof value === 'string') {
      size = value;
    } else {
      size = `${value}px`;
    }
  }

  return size;
};

const Image = styled.img<IconProps>`
  display: flex;
  width: ${(props) => handleSizeValue(props.width)};
  height: ${(props) => handleSizeValue(props.height)};
  align-items: center;
  justify-content: center;

  ${(props) => props.maxHeight && css`
    max-height: ${props.maxHeight}px;
  `};
  ${(props) => props.maxWidth && css`
    max-width: ${props.maxWidth}px;
  `};

  ${(props) => props.xsScreenMaxWidth && css`
    @media (max-width: ${breakpoints.sm}px) {
      max-width: ${props.xsScreenMaxWidth}px;
    }
  `};
`;

const Icon: React.FC<IconProps> = (
  {
    alt,
    height,
    iconClick,
    maxHeight,
    maxWidth,
    xsScreenMaxWidth,
    src,
    width,
    className,
    imageDataTestId,
  },
) => (
  <Container className={className}>
    <Image
      src={src}
      alt={alt}
      width={width}
      height={height}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      xsScreenMaxWidth={xsScreenMaxWidth}
      data-testid={imageDataTestId}
    />
    {iconClick && (
      <ClickContainer
        onClick={iconClick}
      />
    )}
  </Container>
);

Icon.defaultProps = {
  iconClick: undefined,
  width: undefined,
  height: undefined,
  maxWidth: undefined,
  maxHeight: undefined,
  xsScreenMaxWidth: undefined,
  alt: undefined,
  className: undefined,
  imageDataTestId: undefined,
};

export default Icon;
