export interface ApplicationState {
  isMobilePortrait: boolean;
  isLandscape: boolean;
  page: string;
  viewType: string;
  sportsLocation: string;
  rsiApiLoaded: boolean;
  viewArgsLoaded: boolean;
  isSupportChatOpen: boolean;
  isPlayerChatOpen: boolean;
  isGamingMode: boolean;
  showGameTopBar: boolean;
  screenWidthType: ScreenWidthType;
}

export enum ScreenWidthType {
  SMALL,
  MEDIUM,
  LARGE,
}
