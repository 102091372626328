import { createGlobalStyle } from 'styled-components';

const GlobalBottomNavigationStyle = createGlobalStyle`
  #rsi-bottom-navigation > div {
    height: 65px;
  }

  .recent-games {
    bottom: 65px;
  }

  .jackpot-ticker-feed,
  .page-newsfeed-panel {
    display: none !important;
  }

  body {
    @media(max-width: 991px) {

      .modal-window.modal-full-md {
        padding-bottom: 65px !important;
      }
    }

    @media(max-width: 767px) {

      .modal-window.modal-full-sm {
        padding-bottom: 65px !important;
      }
    }

    @media(max-width: 479px) {

      .modal-window.modal-full-mxs {
        padding-bottom: 65px !important;
      }
    }

    &,
    iframe[data-test-id="ChatWidgetMobileWindow"],
    .navbar-header .dropdown-menu:not([aria-labelledby="dropdown-leagues"]) {
      padding-bottom: 65px !important;
    }

    .modal-window.modal-maximized {
      padding-bottom: 65px !important;
    }

    .mod-KambiBC-betslip,
    .KambiBC-betslip {
      bottom: 65px !important;
    }

    .randomizer-modal {

      .modal-container {
        padding-bottom: 60px;
      }
    }
  }
`;

export default GlobalBottomNavigationStyle;
