import { AnyAction } from 'redux';
import { PlayerState } from '../../interfaces/player';
import { playerActionTypes } from '../actionTypes';

const initialState: PlayerState = {
  player: null,
  profile: null,
  loggedIn: false,
  loyalty: null,
  balance: {
    balance: null,
    breakdown: {},
  },
  newBalance: null,
  bonus: {
    bar: null,
  },
  tokens: {
    customTokens: 0,
    loyaltyTokens: 0,
    totalTokens: 0,
  },
};

export default (state = initialState, action: AnyAction): typeof initialState => {
  switch (action.type) {
    case playerActionTypes.SET_PLAYER:
      return {
        ...state,
        player: action.player,
        loggedIn: !!action.player,
      };
    case playerActionTypes.SET_PLAYER_PROFILE:
      return {
        ...state,
        profile: {
          ...action.profile,
          // as profile can sometimes not have avatarURL we fall back to previous value if possible
          avatarImageURL: action.profile?.avatarImageURL || state.profile?.avatarImageURL || null,
        },
      };
    case playerActionTypes.SET_PLAYER_AVATAR:
      return {
        ...state,
        profile: {
          ...state.profile,
          avatarImageURL: action.avatar,
        },
      };
    case playerActionTypes.SET_PLAYER_LOYALTY_BAR:
      return {
        ...state,
        loyalty: action.loyalty,
      };
    case playerActionTypes.SET_PLAYER_BONUS_BAR:
      return {
        ...state,
        bonus: {
          ...state.bonus,
          bar: action.bonusBar,
        },
      };
    case playerActionTypes.SET_PLAYER_BALANCE:
      return {
        ...state,
        balance: action.balance,
      };
    case playerActionTypes.SET_PLAYER_NEW_BALANCE:
      return {
        ...state,
        newBalance: action.newBalance,
      };
    case playerActionTypes.SET_PLAYER_CUSTOM_TOKENS: {
      const count = action.tokenCount || 0;

      return {
        ...state,
        tokens: {
          totalTokens: count + state.tokens.loyaltyTokens,
          customTokens: count,
          loyaltyTokens: state.tokens.loyaltyTokens,
        },
      };
    }
    case playerActionTypes.SET_PLAYER_LOYALTY_TOKENS: {
      const count = action.tokenCount || 0;

      return {
        ...state,
        tokens: {
          totalTokens: count + state.tokens.customTokens,
          customTokens: state.tokens.customTokens,
          loyaltyTokens: count,
        },
      };
    }
    default:
      return state;
  }
};
