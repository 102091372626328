import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../interfaces';
import { playerActions } from '../store/actions';

export default (): void => {
  const [playerState, setPlayerState] = useState<boolean | null>(null);
  const dispatch = useDispatch();
  const rsiApiLoaded = useSelector((state: RootState) => state.application.rsiApiLoaded);

  const playerChange = (): void => {
    dispatch(playerActions.setPlayer(rsiApi.getPlayer()));
  };

  const initializePlayerChange = (): void => {
    setPlayerState(!!rsiApi.getPlayer());

    rsiApi.on(rsiApi.getEvent('STATUS_CHANGE'), () => {
      const isActuallyLoggedIn = !!rsiApi.getPlayer();
      setPlayerState(isActuallyLoggedIn);
    });
  };

  useEffect(() => {
    if (!rsiApiLoaded) {
      return;
    }

    if (playerState === null) {
      initializePlayerChange();
      return;
    }

    playerChange();
    // eslint-disable-next-line
  }, [rsiApiLoaded, playerState]);
};
