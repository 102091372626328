import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '../SideNavigation/Icon';

interface ProgressCircleProps {
  progress: number;
  image: string;
  color?: string;
  compact?: boolean;
  tinyIcon?: {
    icon: string;
    content: string | number;
  };
}

const calculateGradient = (progress: number, color: string, background: string): string => {
  const maxPercent = 100;
  const colorBar = color;
  const colorBack = background;

  const increment = 360 / maxPercent;
  const half = Math.round(maxPercent / 2);
  let backgroundImage: string;
  if (progress < half) {
    const nextdeg = 90 + (increment * progress);
    backgroundImage = `linear-gradient(90deg, ${colorBack} 50%, transparent 50%, transparent),
      linear-gradient(${nextdeg}deg, ${colorBar} 50%, ${colorBack} 50%, ${colorBack})`;
  } else {
    const nextdeg = -90 + (increment * (progress - half));
    backgroundImage = `linear-gradient(${nextdeg}deg, ${colorBar} 50%, transparent 50%, transparent),
      linear-gradient(270deg, ${colorBar} 50%, ${colorBack} 50%, ${colorBack})`;
  }

  return backgroundImage;
};

const ProgressContainer = styled.div<Pick<ProgressCircleProps, 'compact'>>`
  position: relative;
  ${(props) => props.compact && css`
    margin-bottom: 4px;
    height: 24px;
    width: 24px;
  `}
  ${(props) => !props.compact && css`
    margin-right: 4px;
    height: 44px;
    width: 44px;
  `}
`;

const ProgressBar = styled.div<Pick<ProgressCircleProps, 'progress'>>`
  background: ${(props) => calculateGradient(props.progress, props.theme.components.progress.color, props.theme.components.progress.background)};
  border-radius: 50%;
  height: 100%;
  width: 100%;
  position: relative;
  transition: all .3s linear;
  transform: rotate(180deg);
`;

const ProgressContent = styled.div<Pick<ProgressCircleProps, 'image'>>`
  background-color: ${(props) => props.theme.themed.background.primary};
  background-image: url('${(props) => props.image}');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom center;
  border-radius: 50%;
  height: 100%;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  transform: scale(.88) rotate(-180deg);
  width: 100%;
`;

const IconContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(6px);
  line-height: 0;
`;

const IconContent = styled.div<Partial<ProgressCircleProps>>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  color: #000000;

  ${(props) => props.color && css`
    color: ${props.color};
  `}
  ${(props) => props.compact && css`
    font-size: 8px;
    line-height: 8px;
  `}
  ${(props) => !props.compact && css`
    font-size: 10px;
  `}
`;

const ProgressCircle: React.FC<ProgressCircleProps> = (
  {
    compact,
    image,
    progress,
    tinyIcon,
    color,
  },
) => (
  <ProgressContainer compact={compact}>
    <ProgressBar progress={progress}>
      <ProgressContent image={image} />
    </ProgressBar>
    {tinyIcon && (
      <IconContainer>
        <Icon
          src={`cms/navigationAssets/${tinyIcon.icon}`}
          height={compact ? 20 : 25}
          width={compact ? 20 : 25}
        />
        <IconContent color={color} compact={compact}>
          {tinyIcon.content}
        </IconContent>
      </IconContainer>
    )}
  </ProgressContainer>
);

ProgressCircle.defaultProps = {
  color: undefined,
  compact: undefined,
  tinyIcon: undefined,
};

export default ProgressCircle;
