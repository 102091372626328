import { AnyAction } from 'redux';
import { ApplicationState, ScreenWidthType } from '../../interfaces';
import { applicationActionTypes } from '../actionTypes';

const initialState: ApplicationState = {
  isMobilePortrait: false,
  isLandscape: false,
  page: '',
  viewType: '',
  sportsLocation: sessionStorage.getItem('sportsLocation') || 'page=sports#home',
  rsiApiLoaded: false,
  viewArgsLoaded: false,
  isSupportChatOpen: false,
  isPlayerChatOpen: false,
  isGamingMode: false,
  showGameTopBar: false,
  screenWidthType: ScreenWidthType.SMALL,
};

export default (state = initialState, action: AnyAction): ApplicationState => {
  switch (action.type) {
    case applicationActionTypes.SET_MOBILE_PORTRAIT:
      return {
        ...state,
        isMobilePortrait: action.payload,
      };
    case applicationActionTypes.SET_LANDSCAPE:
      if (state.isLandscape === action.isLandscape) {
        return state;
      }
      return {
        ...state,
        isLandscape: action.isLandscape,
      };
    case applicationActionTypes.SET_SCREEN_WIDTH_TYPE:
      return {
        ...state,
        screenWidthType: action.payload,
      };
    case applicationActionTypes.SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case applicationActionTypes.SET_VIEW_TYPE:
      return {
        ...state,
        viewType: action.payload,
      };
    case applicationActionTypes.SET_SPORTS_LOCATION:
      return {
        ...state,
        sportsLocation: action.payload,
      };
    case applicationActionTypes.SET_RSIAPI_STATUS:
      return {
        ...state,
        rsiApiLoaded: action.payload,
      };
    case applicationActionTypes.SET_VIEWARGS_STATUS:
      return {
        ...state,
        viewArgsLoaded: action.payload,
      };
    case applicationActionTypes.SET_SUPPORT_CHAT_OPEN_STATUS:
      return {
        ...state,
        isSupportChatOpen: action.payload,
      };
    case applicationActionTypes.SET_PLAYER_CHAT_OPEN_STATUS:
      return {
        ...state,
        isPlayerChatOpen: action.payload,
      };
    case applicationActionTypes.SET_GAMING_MODE:
      return {
        ...state,
        isGamingMode: action.payload,
      };
    case applicationActionTypes.SET_SHOW_GAME_TOP_BAR:
      return {
        ...state,
        showGameTopBar: action.payload,
      };
    default:
      return state;
  }
};
