export interface RsiApiTrigger {
  event: RsiApiEventTypes;
  payload?: any;
}

export enum RsiApiEventTypes {
  ToggleCasinoQuickLaunch = 'TOGGLE_CASINO_QUICK_LAUNCH',
  ToggleSupportChatWindow = 'TOGGLE_SUPPORT_CHAT_WINDOW',
  HideSupportChatWindow = 'HIDE_SUPPORT_CHAT_WINDOW',
}
