import { PlatformVisibility, UserStatusVisibility } from './ConfigsState';
import { RsiEventParams } from './rsiEventBus';

export interface SideNavigationState {
  show: boolean;
  brandsOpen: boolean;
}

export interface SideNavigationConfig {
  items: ConfigItem[];
}

export type ConfigItem =
  | LinkImageItem
  | ImageItem
  | SeparatorItem
  | BadgeItem
  | BottomSheetModalItem;

export enum ItemTypes {
  Link = 'LINK',
  Separator = 'SEPARATOR',
  Logout = 'LOGOUT',
  Prizes = 'MY_PRIZES',
  Badge = 'BADGE',
  BottomSheetModal = 'BOTTOM_SHEET_MODAL',
}

interface BaseSideNavigationItem<T> {
  type: T;
  platforms: PlatformVisibility;
  conditional: UserStatusVisibility;
}

export type LinkTypes = ItemTypes.Link;

export interface LinkImageItem extends ImageItem<LinkTypes> {
  link: string;
  externalUrl: boolean;
}

export interface BottomSheetModalItem extends ImageItem<ItemTypes.BottomSheetModal> {
  event: { params: RsiEventParams };
}

export interface SeparatorItem extends BaseSideNavigationItem<ItemTypes.Separator> {
  fullWidth: boolean;
}

export type ImageTypes = ItemTypes.Logout | ItemTypes.Prizes;

export interface ImageItem<T = ImageTypes> extends BaseSideNavigationItem<T | ImageTypes> {
  image: string;
  customImage: string;
  translationKey: string;
}

export type BadgeTypes = ItemTypes.Badge;

export interface BadgeItem<T = BadgeTypes> extends ImageItem<T | BadgeTypes> {
  badgeEventName: string;
  onClickEventName: string;
  appLabel: string;
}

export interface BadgeReceiveEvent {
  value: number | string;
  conditionals: BadgeVisibilityConditional[];
}

export interface BadgeVisibilityConditional {
  // Comparison string eg. '>==' or '!=='
  conditional: string;
  compareValue: boolean | string | null;
  // Path representing background image of the badge
  iconPath: string;
  // Random data that will be sent back with the click
  returnData?: unknown;
}
