/**
 * note that the keys are defined by cms
 */
export default {
  logout: 'logout.svg',
  cherry: 'cherry.svg',
  sportsbook: 'sportsbook.svg',
  poker: 'poker-default.svg',
  casinoLive: 'live-casino.svg',
};
