/* eslint-disable import/prefer-default-export */
import { AnyAction } from 'redux';
import { logoActionTypes } from '../actionTypes';
import { LogoState } from '../../interfaces';

export const setLogoPaths = (logoPaths: LogoState): AnyAction => ({
  type: logoActionTypes.SET_LOGO_PATHS,
  logoPaths: {
    primary: `cms/navigationAssets/${logoPaths.primary}`,
    dual: logoPaths.dual ? `cms/navigationAssets/${logoPaths.dual}` : null,
  },
});
