(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("$script"), require("Qs"), require("React"), require("ReactDOM"), require("ReactIntl"), require("ReactRedux"), require("Redux"), require("ReduxThunk"), require("axios"), require("reduxLogger"), require("styled"));
	else if(typeof define === 'function' && define.amd)
		define("RSINavigation", ["$script", "Qs", "React", "ReactDOM", "ReactIntl", "ReactRedux", "Redux", "ReduxThunk", "axios", "reduxLogger", "styled"], factory);
	else if(typeof exports === 'object')
		exports["RSINavigation"] = factory(require("$script"), require("Qs"), require("React"), require("ReactDOM"), require("ReactIntl"), require("ReactRedux"), require("Redux"), require("ReduxThunk"), require("axios"), require("reduxLogger"), require("styled"));
	else
		root["RSINavigation"] = factory(root["$script"], root["Qs"], root["React"], root["ReactDOM"], root["ReactIntl"], root["ReactRedux"], root["Redux"], root["ReduxThunk"], root["axios"], root["reduxLogger"], root["styled"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__879__, __WEBPACK_EXTERNAL_MODULE__448__, __WEBPACK_EXTERNAL_MODULE__24__, __WEBPACK_EXTERNAL_MODULE__314__, __WEBPACK_EXTERNAL_MODULE__568__, __WEBPACK_EXTERNAL_MODULE__566__, __WEBPACK_EXTERNAL_MODULE__894__, __WEBPACK_EXTERNAL_MODULE__647__, __WEBPACK_EXTERNAL_MODULE__300__, __WEBPACK_EXTERNAL_MODULE__377__, __WEBPACK_EXTERNAL_MODULE__404__) {
return 