export default {
  DEFAULT: 'conf/DEFAULT',
  GET_API_CONFIG_SUCCESS: 'conf/GET_API_CONFIG_SUCCESS',
  GET_LANGUAGE_CONFIG_SUCCESS: 'conf/GET_LANGUAGE_CONFIG_SUCCESS',
  GET_TIME_CONFIG_SUCCESS: 'conf/GET_TIME_CONFIG_SUCCESS',
  GET_NAVIGATION_CONFIG_REQUEST: 'conf/GET_NAVIGATION_CONFIG_REQUEST',
  GET_NAVIGATION_CONFIG_SUCCESS: 'conf/GET_NAVIGATION_CONFIG_SUCCESS',
  GET_NAVIGATION_CONFIG_FAILURE: 'conf/GET_NAVIGATION_CONFIG_FAILURE',

  GET_LOYALTY_LEVELS_CONFIG_REQUEST: 'conf/GET_LOYALTY_LEVELS_CONFIG_REQUEST',
  GET_LOYALTY_LEVELS_CONFIG_SUCCESS: 'conf/GET_LOYALTY_LEVELS_CONFIG_SUCCESS',
  GET_LOYALTY_LEVELS_CONFIG_FAILURE: 'conf/GET_LOYALTY_LEVELS_CONFIG_FAILURE',

  GET_SITE_SELECT_CONFIG_REQUEST: 'conf/GET_SITE_SELECT_CONFIG_REQUEST',
  GET_SITE_SELECT_CONFIG_SUCCESS: 'conf/GET_SITE_SELECT_CONFIG_SUCCESS',
  GET_SITE_SELECT_CONFIG_FAILURE: 'conf/GET_SITE_SELECT_CONFIG_FAILURE',

  GET_TOURNAMENTS_CONFIG_REQUEST: 'conf/GET_TOURNAMENTS_CONFIG_REQUEST',
  GET_TOURNAMENTS_CONFIG_SUCCESS: 'conf/GET_TOURNAMENTS_CONFIG_SUCCESS',
  GET_TOURNAMENTS_CONFIG_FAILURE: 'conf/GET_TOURNAMENTS_CONFIG_FAILURE',
};
