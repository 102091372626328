import styled, { css } from 'styled-components';
import breakpoints from '../../../constants/breakpoints';

interface LinkProps {
  active?: boolean;
}

export const BaseHeaderLink = css<LinkProps>`
  position: relative;
  min-width: 70px;
  text-transform: none;
  margin: 0;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.theme.themed.foreground.linkColor};
  background-color: transparent;
  border: 2px solid transparent;
  user-select: none;
  white-space: nowrap;
  text-align: center;
  text-decoration: none;
  transition: color 200ms;

  ${(props) => props.active && css`
    color: ${props.theme.themed.foreground.linkActive};
  `}

  &:focus,
  &:hover {
    color: ${(props) => props.theme.themed.foreground.linkColor};

    ${(props) => props.active && css`
      color: ${props.theme.themed.foreground.linkActive};
    `}

    text-decoration: none !important;
  }

  &:focus {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  &:hover {
    color: ${(props) => props.theme.themed.foreground.linkActive} !important;
  }
`;

export const LinkButton = styled.button`
  ${BaseHeaderLink};
`;

export const Link = styled.a`
  ${BaseHeaderLink};

  @media (max-width: ${breakpoints.xs}px) {
    min-width: 0;
    padding: 0;
  }
`;
