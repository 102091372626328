import styled, { css } from 'styled-components';

const BrandIconShared = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  margin-bottom: 2px;
`;

export const BrandHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 5px;
  cursor: pointer;
  align-items: center;
  margin-right: 5px;
  user-select: none;

  @media (max-width: 340px) {
    padding: 0;
  }
`;

export const CurrentBrandIcon = styled.div`
  ${BrandIconShared}
`;

export const CurrentBrandIconLink = styled.a`
  ${BrandIconShared}
`;

export const CurrentBrand = styled.div`
  display: flex;
  color: ${(props) => props.theme.themed.foreground.base};
  font-size: 8px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
`;

export const DualBrandSeparator = styled.div`
  height: 40%;
  width: 1px;
  background-color: #E8EFF7;
  opacity: .7;
  margin: auto 6px;
`;

interface BrandSelectProps {
  hasBottomNav: boolean;
}

export const BrandSelect = styled.div<BrandSelectProps>`
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  ${(props) => props.hasBottomNav && css`
    bottom: 65px;
  `};
  overflow: auto;
  background-color: ${(props) => props.theme.plain.background.primary};
  z-index: 1;
`;

interface BrandItemProps {
  active: boolean;
  group: boolean;
  indentLevel?: number;
}

interface BrandItemBrandProps {
  active: boolean;
}

const BrandItem = css<BrandItemProps>`
  position: relative;
  display: block;
  padding: 16px;
  color: ${(props) => props.theme.plain.foreground.linkColor};
  font-size: 14px;
  font-weight: 500;
  text-decoration: none !important;
  transition: color 200ms;

  ${(props) => props.indentLevel && css`
    padding-left: ${(props.indentLevel * 16) + 16}px;
  `};

  ${(props) => !props.group && css`
    &:focus,
    &:hover {
      text-decoration: none !important;
      color: ${props.theme.plain.foreground.linkHover} !important;
    }

    ${props.active && css`
      color: ${props.theme.plain.foreground.linkActive};
      font-weight: 700;

      &:focus,
      &:hover {
        color: ${props.theme.plain.foreground.linkActive} !important;
      }
    `};

    & + &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 15px;
      right: 15px;
      height: 1px;
      background-color: ${props.theme.plain.foreground.dividers};
    }
  `};
`;

export const BrandItemLink = styled.a<BrandItemProps>`
  ${BrandItem};
`;

export const BrandItemButton = styled.div<BrandItemProps>`
  ${BrandItem};
`;

export const BrandItemGroup = styled.div``;

export const BrandItemBrand = styled.span<BrandItemBrandProps>`
  margin-left: 4px;
  font-size: 12px;
  opacity: .6;
  ${(props) => props.active && css`
    opacity: .7;
  `};
`;
