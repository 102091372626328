import { createGlobalStyle, css } from 'styled-components';
import { add, getTopNavHeight } from '../../utils';
import { ScreenWidthType } from '../../interfaces';

interface GameFrameGlobalProps {
  hasTopNav: boolean;
  hasBottomNav: boolean;
  hasGameTopBar: boolean;
  screenWidthType: ScreenWidthType;
}

const bottomNavHeight = '65px';

const getNavigationTotalHeight = (props: GameFrameGlobalProps, extra?: number) => {
  let height = '0px';

  if (props.hasTopNav) {
    height = add(height, getTopNavHeight(props.screenWidthType, props.hasGameTopBar))!;
  }

  if (props.hasBottomNav) {
    height = add(height, bottomNavHeight)!;
  }

  if (extra) {
    height = add(height, extra)!;
  }

  return height;
};

// stylelint-disable selector-max-id,max-nesting-depth, order/order
const GameFrameGlobalStyle = createGlobalStyle<GameFrameGlobalProps>`

  .game-frame-container {
    top: 0 !important;


    ${(props) => css`
      bottom: ${getNavigationTotalHeight(props)} !important;
    `}
  }

  body.desktop .game-frame-container {
    ${(props) => css`
      bottom: ${getNavigationTotalHeight(props, 40)} !important;
    `}
  }
`;

export default GameFrameGlobalStyle;
