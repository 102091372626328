/* eslint-disable import/prefer-default-export */
import { ScreenWidthType } from '../interfaces';

export const getTopNavHeight = (screenWidthType: ScreenWidthType, hasGameTopBar: boolean) => {
  switch (screenWidthType) {
    case ScreenWidthType.SMALL: {
      return hasGameTopBar ? 125 : 60;
    }
    case ScreenWidthType.MEDIUM: {
      return 60;
    }
    default: {
      return 105;
    }
  }
};
