// eslint-disable-next-line
import { combineReducers } from 'redux';
import application from './application';
import betslip from './betslip';
import bottomNavigation from './bottomNavigation';
import invite from './invite';
import logo from './logo';
import player from './player';
import sideNavigation from './sideNavigation';
import topNavigation from './topNavigation';
import configs from './configs';

const asyncReducers = {};

export default combineReducers({
  configs,
  sideNavigation,
  bottomNavigation,
  topNavigation,
  application,
  betslip,
  player,
  invite,
  logo,
  ...asyncReducers,
});
