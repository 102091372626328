import styled, { css } from 'styled-components';

export default styled.div<any>`
  display: flex;
  padding-left: 10px;
  margin-bottom: 5px;

  ${(props) => props.noImage && css`
    padding-left: 60px;
  `};
`;
