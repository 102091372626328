import React from 'react';
import { FormattedMessage } from 'react-intl';

interface TranslationProps {
  id?: string;
}

function Translate({ id }: TranslationProps) {
  if (!id) {
    return null;
  }
  return (
    <FormattedMessage id={id} />
  );
}

Translate.defaultProps = {
  id: undefined,
};

export default Translate;
