import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../interfaces';

export default (): void => {
  const { rsiApiLoaded } = useSelector((state: RootState) => state.application);

  useEffect(() => {
    if (rsiApiLoaded) {
      rsiApi.on(rsiApi.getEvent('BETSLIP_TOGGLE'), (payload) => {
        RSIKambiController.setBetslipMaximized(payload);
      });
    }
    // eslint-disable-next-line
  }, [rsiApiLoaded]);
};
