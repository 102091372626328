import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isClient } from '../utils';
import { applicationActions } from '../store/actions';
import { logger } from '../services';

export default (): void => {
  const dispatch = useDispatch();
  let retryCount = 0;

  const initializeViewArgs = (): void => {
    try {
      if (!viewArgs) {
        throw new Error();
      }

      dispatch(applicationActions.setViewArgsStatus(true));

      logger.info('viewArgs ready');
    } catch (e) {
      if (retryCount <= 4) {
        retryCount += 1;
        logger.warn(`Cannot find viewArgs, retry ${retryCount}`);
        setTimeout(() => {
          initializeViewArgs();
        }, 1000);
      } else {
        logger.error('Cannot find viewArgs');
      }
    }
  };

  useEffect(() => {
    if (!isClient) {
      return;
    }

    initializeViewArgs();
    // eslint-disable-next-line
  }, []);
};
