import { AnyAction } from 'redux';
import { bottomNavigationActionTypes } from '../actionTypes';

export const setHidden = (): AnyAction => ({
  type: bottomNavigationActionTypes.SET_HIDDEN,
});

export const setVisible = (): AnyAction => ({
  type: bottomNavigationActionTypes.SET_VISIBLE,
});
