import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '../SideNavigation/Icon';

interface DropdownIconProps {
  open: boolean;
  noDropdownIcon?: boolean;
  isActive?: boolean;
  children?: React.ReactNode;
}

interface DropdownIconContainerProps extends Partial<DropdownIconProps> {
  hasChildren: boolean;
}

const LabelContainer = styled.span<Partial<DropdownIconContainerProps>>`
  ${({ hasChildren }) => hasChildren && css`
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

const IconContainer = styled.span<Partial<DropdownIconContainerProps>>`
  margin-left: 3px;
  transition: transform 180ms;

  ${({ open }) => open && css`
    transform: rotate(-180deg);
  `};
`;

const activeIcon = 'arrow_down_active.svg';
const defaultIcon = 'arrow_down.svg';

const DropdownLabel: React.FC<DropdownIconProps> = ({
  open, noDropdownIcon, isActive, children,
}) => (
  <LabelContainer open={open} hasChildren={!!children}>
    {children}

    {!noDropdownIcon && (
      <IconContainer open={open}>
        <Icon
          src={`cms/navigationAssets/${isActive ? activeIcon : defaultIcon}`}
          height=".5em"
        />
      </IconContainer>
    )}
  </LabelContainer>
);

DropdownLabel.defaultProps = {
  noDropdownIcon: undefined,
  isActive: undefined,
  children: undefined,
};

export default DropdownLabel;
