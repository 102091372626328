import styled, { css } from 'styled-components';
import { breakpoints } from '../../constants';

interface ButtonProps {
  color?: 'primary';
  raised?: boolean;
  bordered?: boolean;
}

function generateButton(props: ButtonProps, backgroundColor: string, foregroundColor: string) {
  return css`
    ${!props.raised && css`
      color: ${backgroundColor};

      &:focus,
      &:hover {
        color: ${backgroundColor} !important;
      }

      &:hover:after {
        opacity: .04;
        background-color: ${backgroundColor};
      }
    `};

    ${props.bordered && css`
      border-color: ${backgroundColor};
    `};

    ${props.raised && css`
      color: ${foregroundColor};
      background-color: ${backgroundColor};

      &:focus,
      &:hover {
        color: ${foregroundColor} !important;
      }
    `};
  `;
}

/*
 * disable ordering as generateButton needs to override rules that come after
 * the definition of invisible after block
 */
// TODO: border-radius should be 6px according to design
// stylelint-disable order/order
const BaseButton = css<ButtonProps>`
  position: relative;
  min-width: 70px;
  text-transform: none;
  margin: 0;
  padding: 5px 15px;
  font-size: 12px;
  font-weight: 700;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 3px;
  user-select: none;
  white-space: nowrap;
  text-align: center;
  text-decoration: none;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    border-radius: inherit;
    opacity: 0;
    transition: opacity 200ms, background-color 200ms;
  }

  &:focus,
  &:hover {
    text-decoration: none !important;
  }

  @media (max-width: ${breakpoints.xs}px) {
    min-width: 50px;
    padding: 5px 10px;
    font-size: 10px;
    border-radius: 6px;
  }

  ${(props) => !props.color && generateButton(props, props.theme.components.buttons.base.color, props.theme.components.buttons.base.background)}
  ${(props) => props.color === 'primary' && generateButton(props, props.theme.components.buttons.primary.color, props.theme.components.buttons.primary.background)}
`;

export const Button = styled.button`
  ${BaseButton};
`;

export const LinkButton = styled.a`
  ${BaseButton};
`;
