import { css } from 'styled-components';

export interface ButtonBaseProps {
  icon?: boolean;
  active?: boolean;
}

export const ButtonStyles = css<ButtonBaseProps>`
  outline: 0;
  cursor: pointer;
  padding: 10px 10px 13px;
  font-size: 8px;
  text-transform: uppercase;
  font-weight: 700;
  color: ${(props) => props.theme.plain.foreground.linkColor};
  background-color: transparent;
  border: 0;
  position: relative;
  ${(props) => props.icon && css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `};
  ${(props) => props.active && css`
    color: ${props.theme.plain.foreground.linkActive} !important;
    &:after {
      content: "";
      display: block;
      background-color: ${props.theme.plain.foreground.linkActive};
      position: absolute;
      bottom: 9px;
      left: 10px;
      right: 10px;
      height: 3px;
    }
  `};
`;
