import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import ProgressCircle from '../Wrappers/ProgressCircle';
import LabeledItem from '../Wrappers/LabeledItem';
import { findConfigItem, isConfigItemAllowed } from '../../utils';
import { RootState } from '../../interfaces';
import { ItemTypes } from '../../interfaces/topNavigation';
import { logger } from '../../services';
import eventBus, { eventTypes } from '../../services/eventBus';

interface PlayerBalanceProps {
  compact?: boolean;
  hideDropdownArrow?: boolean
}

const BalanceContainer = styled.div<Partial<PlayerBalanceProps>>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  padding-bottom: 3px;

  ${(props) => !props.compact && css`
    padding-top: 0;
  `}
  ${(props) => props.compact && css`
    padding-top: 4px;
    padding-bottom: 8px;
  `}
`;

const Balance = styled.span<Partial<PlayerBalanceProps>>`
  ${(props) => !props.compact && css`
    font-size: 18px;
  `}
  ${(props) => props.compact && css`
    font-size: 12px;

    @media (max-width: 440px) {
      max-width: 80px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    @media (max-width: 420px) {
      max-width: 85px;
    }
    @media (max-width: 400px) {
      max-width: 60px;
    }
    @media (max-width: 360px) {
      max-width: 65px;
    }
  `}
  font-weight: 700;
  color: ${(props) => props.theme.themed.foreground.base};
  text-transform: uppercase;
`;

const { OPEN_CASHIER_MENU, CLOSE_CASHIER_MENU } = eventTypes;

// eslint-disable-next-line react/function-component-definition
// @ts-ignore
const PlayerBalance: React.FC<PlayerBalanceProps> = ({ compact, hideDropdownArrow }) => {
  const {
    rsiApiLoaded,
    isGamingMode,
  } = useSelector((state: RootState) => state.application);
  const {
    loggedIn,
    balance,
    newBalance,
  } = useSelector((state: RootState) => state.player);
  const { topNavigation } = useSelector((state: RootState) => state.configs);

  const [isOpen, setIsOpen] = useState(false);

  const balanceConfig = findConfigItem(
    topNavigation?.items || [],
    ItemTypes.Cashier,
  );

  const formatBalance = (value: number | null): string | null => {
    if (rsiApiLoaded) {
      const format = rsiApi.getModule('util/Format');
      return format.Currency(value);
    }
    return null;
  };

  let directions: 'column' | 'row' = 'column';

  if (isGamingMode && !compact) {
    directions = 'row';
  }

  const toggleCashierMenu = useCallback((): void => {
    const event = {
      category: 'Top navigation',
      action: 'click',
      label: `${ItemTypes.Cashier} ${isOpen ? 'close' : 'open'}`,
    };

    RSIAnalytics.event(event);
    logger.info(`Top navigation ${ItemTypes.Cashier} click event`, event);

    eventBus.publish(
      isOpen ? CLOSE_CASHIER_MENU : OPEN_CASHIER_MENU,
      {},
      { toNative: true, withHistory: false },
    );
  }, [isOpen]);

  useEffect(() => {
    if (!rsiApiLoaded) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return (): void => {
      };
    }

    const openMenuCallback = (): void => {
      setIsOpen(true);
    };
    const closeMenuCallback = (): void => {
      setIsOpen(false);
    };

    const openCashierSubscriber = eventBus.subscribe(OPEN_CASHIER_MENU, openMenuCallback);
    const closeCashierSubscriber = eventBus.subscribe(CLOSE_CASHIER_MENU, closeMenuCallback);

    return (): void => {
      openCashierSubscriber.unsubscribe();
      closeCashierSubscriber.unsubscribe();
    };
  }, [rsiApiLoaded]);

  return (
    isConfigItemAllowed(balanceConfig, loggedIn) && (balance || newBalance) && (
      <LabeledItem
        onClick={toggleCashierMenu}
        label={balanceConfig!.translationKey}
        dataTarget="menu-quick-deposit"
        open={false}
        compact={compact}
        direction={directions}
        noDropdownIcon={hideDropdownArrow}
      >
        {!isGamingMode && (
          <BalanceContainer compact={compact}>
            <Balance compact={compact}>
              {balance.balance !== null && formatBalance(balance.balance)}
              {newBalance?.formattedTotal}
            </Balance>
          </BalanceContainer>
        )}

        {isGamingMode && (
          <ProgressCircle
            progress={0}
            compact={compact}
            image="cms/navigationAssets/balance.png"
          />
        )}
      </LabeledItem>
    )
  );
};

PlayerBalance.defaultProps = {
  compact: undefined,
  hideDropdownArrow: false,
};

export default PlayerBalance;
