import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Header, { HeaderContentWrapper, HeaderLeftContainer } from '../../rsi/Header';
import { RootState } from '../../../interfaces';
import { filterConfigItemsAllowed, openBottomSheetModal } from '../../../utils';
import { Link, LinkButton } from './HeaderLink';
import BrandNavigation from './BrandNavigation';
import { ItemTypes } from '../../../interfaces/compactNavigation';
import Translate from '../../Translate';

const CompactHeaderContainer = styled(Header)`
  height: 35px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: ${(props): string => props.theme.themed.background.secondary};
`;

const CompactHeader: React.FC = () => {
  const {
    rsiApiLoaded, page, isGamingMode, isLandscape,
  } = useSelector((state: RootState) => state.application);
  const config = useSelector((state: RootState) => state.configs.config);
  const compactNavigation = useSelector((state: RootState) => state.configs.compactNavigation);
  const loggedIn = useSelector((state: RootState) => state.player.loggedIn);
  const navigationItems = filterConfigItemsAllowed(
    compactNavigation?.items || [],
    loggedIn,
    isGamingMode,
    undefined,
    isLandscape,
  );

  const openSupportChat = (): void => {
    if (rsiApiLoaded) {
      rsiApi.trigger(rsiApi.getEvent('TOGGLE_SUPPORT_CHAT_WINDOW'));
    }
  };

  const shouldShowSiteLabel = (!config?.siteSelection?.enabledPlatforms
    || config?.siteSelection?.enabledPlatforms?.includes(RSIUtils.detector.detailedDeviceType));

  return (
    <CompactHeaderContainer>
      <HeaderContentWrapper>
        <HeaderLeftContainer>
          {shouldShowSiteLabel && (
            <BrandNavigation />
          )}
          {navigationItems.map((item, i): JSX.Element | null => {
            const testId = `${item.translationKey}-${i}`;
            if (item.type === ItemTypes.Link) {
              return (
                <Link
                  key={item.translationKey}
                  href={item.link}
                  rel={item.externalUrl ? 'noreferrer nofollow' : ''}
                  target={item.externalUrl ? '_blank' : ''}
                  active={!!page && item.link.includes(page) && !isGamingMode}
                  data-testid={testId}
                >
                  <Translate id={item.translationKey} />
                </Link>
              );
            }

            if (item.type === ItemTypes.Support) {
              return (
                <LinkButton
                  data-testid={testId}
                  key={item.translationKey}
                  onClick={openSupportChat}
                >
                  <Translate id={item.translationKey} />
                </LinkButton>
              );
            }

            if (item.type === ItemTypes.BottomSheetModal) {
              return (
                <LinkButton
                  key={item.translationKey}
                  data-testid={testId}
                  onClick={(): void => {
                    openBottomSheetModal(item.event.params);
                  }}
                >
                  <Translate id={item.translationKey} />
                </LinkButton>
              );
            }

            return null;
          })}
        </HeaderLeftContainer>
      </HeaderContentWrapper>
    </CompactHeaderContainer>
  );
};

export default CompactHeader;
