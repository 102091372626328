import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actionHandlers, applicationActions } from '../store/actions';
import { isClient } from '../utils';
import { ScreenWidthType } from '../interfaces/application';
import breakpoints from '../constants/breakpoints';
import { setScreenWidthType } from '../store/actions/application';

export default (): void => {
  const dispatch = useDispatch();
  let currentIsMobilePortrait = false;
  let currentScreenWidth: ScreenWidthType;

  const setNewIsMobile = (): void => {
    const newIsMobilePortrait = window.innerWidth < breakpoints.lg
      && (!RSIUtils.detector.isMobile || window.innerHeight > window.innerWidth);
    if (currentIsMobilePortrait !== newIsMobilePortrait) {
      currentIsMobilePortrait = newIsMobilePortrait;
      dispatch(applicationActions.setMobilePortrait(newIsMobilePortrait));
    }
  };

  const setOrientationLandscape = (): void => {
    const { isLandscape } = RSIUtils.screenSize();
    dispatch(applicationActions.setOrientationLandscape(isLandscape));
  };

  /**
   * small device (mobile) portrait -> small
   * small device (mobile) landscape -> medium
   * tablet + desktop -> depends on the screen width
   */
  const getScreenWidth = (): ScreenWidthType => {
    if (RSIUtils.detector.isMobile && !RSIUtils.detector.isTablet) {
      return window.innerHeight > window.innerWidth
        ? ScreenWidthType.SMALL
        : ScreenWidthType.MEDIUM;
    }
    if (window.innerWidth < breakpoints.md) {
      return ScreenWidthType.SMALL;
    }
    if (window.innerWidth < breakpoints.lg) {
      return ScreenWidthType.MEDIUM;
    }
    return ScreenWidthType.LARGE;
  };

  const updateScreenWidthType = (): void => {
    const newScreenWidth = getScreenWidth();
    if (currentScreenWidth !== newScreenWidth) {
      currentScreenWidth = newScreenWidth;
      dispatch(setScreenWidthType(newScreenWidth));
    }
  };

  const setPushStateListener = (): void => {
    window.addEventListener('pushState', () => {
      // @TODO PRM-4516
      // @ts-ignore
      dispatch(actionHandlers.handleLocation());
    });
  };

  const triggerDelayedResize = (): void => {
    /*
     * PRM-2749 orientation change happens before resize meaning we will not get
     * measurements. This is why we give the app time to complete the
     * orientation change and we trigger the resize again
    */

    window.setTimeout(() => {
      window.dispatchEvent(new Event('resize'));

      if (RSIUtils.detector.isIOS) {
        window.setTimeout(() => {
          window.dispatchEvent(new Event('resize'));

          window.setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
          }, 200);
        }, 200);
      }
    });
  };

  const resizeHandler = (): void => {
    setNewIsMobile();
    setOrientationLandscape();
    updateScreenWidthType();
  };

  useEffect(() => {
    if (!isClient) {
      return;
    }

    resizeHandler();
    setPushStateListener();

    window.addEventListener('resize', resizeHandler);

    // eslint-disable-next-line consistent-return
    return (): void => {
      window.removeEventListener('resize', resizeHandler);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isClient) {
      return;
    }

    window.addEventListener('orientationchange', triggerDelayedResize);

    // eslint-disable-next-line consistent-return
    return (): void => {
      window.removeEventListener('orientationchange', triggerDelayedResize);
    };
    // eslint-disable-next-line
  }, []);
};
