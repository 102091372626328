import { AnyAction } from 'redux';

import { sideNavigationActionTypes } from '../actionTypes';
import { SideNavigationState } from '../../interfaces/sideNavigation';

const initialState: SideNavigationState = {
  show: false,
  brandsOpen: false,
};

export default (state = initialState, action: AnyAction): SideNavigationState => {
  switch (action.type) {
    case sideNavigationActionTypes.TOGGLE_SHOW:
      return {
        ...state,
        show: !state.show,
      };
    case sideNavigationActionTypes.SET_VISIBLE:
      return {
        ...state,
        show: action.show,
      };
    case sideNavigationActionTypes.SET_BRANDS_OPEN:
      return {
        ...state,
        brandsOpen: action.brandsOpen,
      };
    default:
      return state;
  }
};
