/* eslint-disable import/prefer-default-export */

import { RsiEventParams, TogglePlayerChatPayload } from '../interfaces';
import { eventBus, logger } from '../services';

export const openBottomSheetModal = (params: RsiEventParams): void => {
  RSIEventBus.publish(RSIEventBus.eventTypes.NAVIGATION_MODAL_OVERLAY_REQUEST, {
    type: RSIEventBus.eventTypes.NAVIGATION_MODAL_OVERLAY_REQUEST,
    value: params,
  });
};

export const sendUserEngagementDataForClickEvents = (
  category: string,
  target: string,
): void => {
  const analyticsEvent = {
    category,
    action: 'click',
    label: target.replace('?page=', ''),
  };
  logger.info('Bottom navigation click event', analyticsEvent);
  RSIEventBus.publish(
    RSIEventBus.eventTypes.RSI_BOTTOM_NAVIGATION_CLICK,
    analyticsEvent,
    true,
  );
  RSIAnalytics.event(analyticsEvent);
};

export const togglePlayerChatWindow = (payload?: TogglePlayerChatPayload) => {
  eventBus.publish(eventBus.eventTypes.TOGGLE_PLAYER_CHAT_WINDOW, payload);
};
