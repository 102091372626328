/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-body-style */
import { AnyAction } from 'redux';
import { sideNavigationActionTypes } from '../actionTypes';

export const toggleSideNavigation = (): AnyAction => ({
  type: sideNavigationActionTypes.TOGGLE_SHOW,
});

export const setVisible = (setShow: boolean): AnyAction => {
  /*
   * PRM-2784
   * Trigger side navigation visible event due to portal having to display
   * elapsed session time to logged in users
   */
  if (window.rsiApi) {
    rsiApi.trigger(rsiApi.getEvent('SET_SIDENAVIGATION_VISIBILITY'), setShow);
  }

  return ({
    type: sideNavigationActionTypes.SET_VISIBLE,
    show: setShow,
  });
};

export const setBrandsOpen = (setOpen: boolean): AnyAction => {
  return ({
    type: sideNavigationActionTypes.SET_BRANDS_OPEN,
    brandsOpen: setOpen,
  });
};
