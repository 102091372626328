/* eslint-disable max-len, arrow-body-style */

import {
  WithPlatformVisibility,
  WithProductVerticalVisibility, WithUserStatusVisibility,
} from '../interfaces';
import { viewTypes } from '../constants';

const hasPlatformVisibilityGuard = (item: any): item is WithPlatformVisibility => {
  return !!item.platforms;
};

const hasConditionalVisibilityGuard = (item: any): item is WithUserStatusVisibility => {
  return !!item.conditional;
};

const hasProductVerticalGuard = (item: any): item is WithProductVerticalVisibility => {
  return !!item.products;
};

export const isPlatformAllowed = <T extends WithPlatformVisibility>(item: T): boolean => {
  if (RSIUtils.detector.isAndroid) {
    if (RSIUtils.detector.isApp) {
      return item.platforms.androidApp;
    }
    return item.platforms.androidBrowser;
  }

  if (RSIUtils.detector.isIOS) {
    if (RSIUtils.detector.isApp) {
      return item.platforms.iosApp;
    }
    return item.platforms.iosBrowser;
  }

  return item.platforms.desktop;
};

export const filterDeniedPlatforms = <T extends WithPlatformVisibility>(items: T[]): T[] => {
  if (!RSIUtils || !RSIUtils.detector) {
    return [];
  }

  return items.filter((item) => isPlatformAllowed(item));
};

export const isCurrentGamingModeAllowed = <T extends WithUserStatusVisibility>(item: T, isGamingMode: boolean): boolean => {
  return (isGamingMode || !item.conditional.playModeOnly) && (!isGamingMode || !item.conditional.disabledInGamingMode);
};

export const isUserStatusAllowed = <T extends WithUserStatusVisibility>(item: T, loginStatus: boolean): boolean => {
  return (loginStatus && item.conditional.loggedIn) || (!loginStatus && item.conditional.loggedOut);
};

export const isProductVerticalAllowed = <T extends WithProductVerticalVisibility>(item: T, viewType: string): boolean => {
  return !viewType || (viewType === viewTypes.DEFAULT && item.products.default) || (viewType === viewTypes.SPORTSBOOK && item.products.sportsbook) || (viewType === viewTypes.CASINO && item.products.casino)
|| (viewType === viewTypes.POKER && item.products.poker);
};

export const isScreenOrientationAllowed = (item: WithUserStatusVisibility, isLandscape: boolean): boolean => {
  if (isLandscape) {
    return item.conditional.showLandscape;
  }
  return item.conditional.showPortrait;
};

export const filterDeniedConditionals = <T extends WithUserStatusVisibility>(items: T[], loginStatus: boolean): T[] => (
  items.filter((item) => (
    isUserStatusAllowed(item, loginStatus)
  ))
);

export const isConfigItemAllowed = <T extends WithPlatformVisibility | WithUserStatusVisibility>(item: T | null, loginStatus: boolean, gamingMode = false, viewType = '', isLandscape = false): boolean => {
  return !!item
    && !!RSIUtils?.detector
    && (!hasPlatformVisibilityGuard(item) || isPlatformAllowed(item))
    && (
      !hasConditionalVisibilityGuard(item)
      || (
        isUserStatusAllowed(item, loginStatus)
        && isCurrentGamingModeAllowed(item, gamingMode)
        && isScreenOrientationAllowed(item, isLandscape)
      )
    )
    && (!hasProductVerticalGuard(item) || isProductVerticalAllowed(item, viewType));
};

export const filterConfigItemsAllowed = <T extends WithPlatformVisibility | WithUserStatusVisibility>(items: T[], loginStatus: boolean, isGamingMode = false, viewType = '', isLandscape = false): T[] => {
  return items.filter((item) => isConfigItemAllowed(item, loginStatus, isGamingMode, viewType, isLandscape));
};

interface WithType<T> {
  type: T;
}

export const findConfigItem = <T extends WithType<any>, U>(items: T[], type: U): T | null => {
  return items.find((item) => item.type === type) || null;
};
