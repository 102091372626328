import { parse } from 'qs';
import { Dispatch } from 'redux';
import { applicationActions } from '.';
import { RootState, RsiApiTrigger } from '../../interfaces';
import { pages } from '../../constants';
import { logger } from '../../services';

export const handleLocation = () => (dispatch: Dispatch, getState: () => RootState): void => {
  const { search = '', hash = '' } = window.location;
  const { page, feed, subpage } = parse(search, { ignoreQueryPrefix: true });
  if (page && typeof page === 'string') {
    const currentPage = getState().application.page;
    if (page !== currentPage) {
      dispatch(applicationActions.setPage(subpage ? `page=${page}&subpage=${subpage}` : page));
    }

    if (page === pages.SPORTSBOOK) {
      const sportsFeed = feed ? `&feed=${feed}` : '';
      dispatch(applicationActions.setSportsLocation(`page=${page}${sportsFeed}${hash}`));
    }
  }
};

// eslint-disable-next-line max-len
export const handleRsiApiTrigger = ({ event, payload }: RsiApiTrigger, rsiApiLoaded: boolean): void => {
  if (rsiApiLoaded) {
    rsiApi.trigger(rsiApi.getEvent(event), payload);
  } else {
    logger.error(`[rsiApi] Cannot call rsiAction for ${event}, rsiApi not loaded`);
  }
};
