import React from 'react';
import styled, { css } from 'styled-components';
import { popOut } from '../rsi/animations';

interface BetCounterProps {
  className?: string;
  count: number;
}

const BetCounter: React.FC<BetCounterProps> = ({ className, count }) => (
  <div className={className}>
    {count}
  </div>
);

BetCounter.defaultProps = {
  className: undefined,
};

interface StyledBetCounterProps {
  animated: boolean;
}

const StyledBetCounter = styled(BetCounter) <StyledBetCounterProps>`
  border-radius: 50%;
  width: 11px;
  height: 11px;
  border: solid 1.5px ${({ theme }) => theme.themed.background.primary};
  background-color: ${({ theme }) => theme.plain.background.badge};
  color: ${({ theme }) => theme.plain.foreground.base};
  position: absolute;
  z-index: 2;
  top: 5px;
  right: 20px;
  font-size: 7px;
  font-weight: 700;
  line-height: 1;
  ${({ animated }) => animated && css`
    animation: ${popOut} 2s linear;
  `}
`;

export default StyledBetCounter;
