import styled, { css } from 'styled-components';

interface SeparatorProps {
  offsetted?: boolean;
}

// stylelint-disable order/order
export default styled.div<SeparatorProps>`
  position: relative;
  height: 1px;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${(props) => props.theme.themed.foreground.dividers};
  }

  ${(props) => props.offsetted && css`
    &:after {
      left: 70px;
    }
  `}
`;
