import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { BetslipItem } from '../../interfaces/bottomNavigation';
import { handleRsiApiTrigger } from '../../store/actions/handlers';
import Icon from '../SideNavigation/Icon';
import { RootState, RsiApiEventTypes } from '../../interfaces';
import BetCounter from './BetCounter';
import { usePrevious } from '../../hooks';
import Button from '../rsi/Button';
import { logger } from '../../services';
import { sendUserEngagementDataForClickEvents, togglePlayerChatWindow } from '../../utils';
import Translate from '../Translate';

interface BetSlipProps {
  betslipConfig: BetslipItem;
}

const animationDuration = 2000;

const StyledIcon = styled(Icon)`
  margin-bottom: 5px;
`;

const Betslip: React.FC<BetSlipProps> = ({ betslipConfig }) => {
  const dispatch = useDispatch();
  const isSupportChatOpen = useSelector((state: RootState) => state.application.isSupportChatOpen);
  const isPlayerChatOpen = useSelector((state: RootState) => state.application.isPlayerChatOpen);
  const betslip = useSelector((state: RootState) => state.betslip);
  const [animated, setAnimated] = useState(false);
  const prevItemsCount = usePrevious(betslip.itemsCount);
  const [itemsCount, setItemsCount] = useState(betslip.itemsCount);
  const { rsiApiLoaded } = useSelector((state: RootState) => state.application);

  useEffect(() => {
    if (betslip.isMaximized && isSupportChatOpen) {
      handleRsiApiTrigger({ event: RsiApiEventTypes.HideSupportChatWindow }, rsiApiLoaded);
    }
  }, [dispatch, betslip.isMaximized, isSupportChatOpen, rsiApiLoaded]);

  useEffect(() => {
    setItemsCount(betslip.itemsCount);
    if (!betslip.isMaximized
      && betslip.itemsCount > prevItemsCount
      && betslip.itemsCount
      && !animated
    ) {
      setTimeout(() => {
        setAnimated(true);

        setTimeout(() => {
          setAnimated(false);
        }, animationDuration);
      }, 450);
    }

    // eslint-disable-next-line
  }, [betslip.itemsCount]);

  const toggleShow = (): void => {
    try {
      logger.debug(`Bottom navigation betslip ${betslip.isMaximized ? 'close' : 'open'}`);
      // eslint-disable-next-line no-empty
    } catch (_) { }
    // Toggle player chat only if it is open (basically close it)
    // when user clicks on the betslip
    if (isPlayerChatOpen) {
      togglePlayerChatWindow();
    }
    RSIKambiController.setBetslipMaximized(!betslip.isMaximized);
  };

  const handleClick = () => {
    toggleShow();
    sendUserEngagementDataForClickEvents('Bottom Navigation', `Betslip ${betslip.isMaximized ? 'closed' : 'opened'}`);
  };

  const icon = (config: BetslipItem): string => {
    if (!betslip.isEnabled) {
      return config.disabledImage;
    }
    if (betslip.isMaximized) {
      return config.activeImage;
    }

    return config.image;
  };

  return (
    <Button
      icon
      betslip
      active={betslip.isMaximized && betslip.isEnabled}
      disabled={!betslip.isEnabled || !itemsCount}
      onClick={handleClick}
      animated={animated}
    >
      {betslip.isEnabled && betslip.itemsCount > 0 && (
        <BetCounter
          count={itemsCount}
          animated={animated}
        />
      )}
      <StyledIcon
        height={26}
        width={26}
        src={`cms/navigationAssets/${icon(betslipConfig)}`}
      />
      <Translate id={betslipConfig.translationKey} />
    </Button>
  );
};

export default Betslip;
