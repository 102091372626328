import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import Separator from './Separator';
import SideNavigationItemContainer from './SideNavigationItemContainer';
import { SideNavigationButton, SideNavigationLink } from './SideNavigationLink';

import { sideNavigationActions } from '../../store/actions';
import { isValidImage, logout, openBottomSheetModal } from '../../utils';
import BadgedText from '../rsi/BadgedText';
import { imageMap } from '../../constants';
import {
  BadgeItem,
  BadgeReceiveEvent,
  BadgeVisibilityConditional,
  BottomSheetModalItem,
  ConfigItem,
  ImageItem,
  ItemTypes,
  LinkImageItem,
} from '../../interfaces/sideNavigation';
import { RootState } from '../../interfaces';
import { logger } from '../../services';
import Translate from '../Translate';

interface RowProperties {
  itemConfig: ConfigItem;
}

const Image = styled.img`
  flex-grow: 0;
  width: 30px;
  height: 30px;
  margin-right: 18px;
`;

const ExternalImage = styled.img`
  flex-grow: 0;
  width: 14px;
  height: 14px;
`;

const LinkText = styled.span`
  flex-grow: 1;
`;

const SideNavigationRow: React.FC<RowProperties> = ({ itemConfig }) => {
  const dispatch = useDispatch();
  const { page } = useSelector((state: RootState) => state.application);
  const { tokens } = useSelector((state: RootState) => state.player);

  const [badgeValue, setBadgeValue] = useState<BadgeReceiveEvent | null>(null);
  const [
    activeBadgeCondition,
    setActiveBadgeCondition,
  ] = useState<BadgeVisibilityConditional | null>(null);

  useEffect(() => {
    if (!badgeValue) {
      return;
    }

    try {
      // we want to find the last matching condition
      // eslint-disable-next-line no-plusplus,no-unsafe-optional-chaining
      for (let i = badgeValue.conditionals?.length - 1; i >= 0; i--) {
        const conditional = badgeValue.conditionals[i];
        const condition = `${badgeValue.value} ${conditional.conditional} ${conditional.compareValue}`;
        // eslint-disable-next-line no-new-func
        if (Function(`"use strict"; return ${condition}`)()) {
          setActiveBadgeCondition(conditional);
          break;
        }
      }
    } catch (e) {
      logger.error(`Failed parsing active badge condition ${e}`);
    }
  }, [badgeValue]);

  const getImage = (
    item: ImageItem | LinkImageItem | BadgeItem | BottomSheetModalItem,
  ): React.ReactNode | null => {
    let imageUrl = null;
    if (item.customImage) {
      imageUrl = item.customImage;
    } else if (isValidImage(item.image)) {
      imageUrl = imageMap[item.image];
    }

    return imageUrl && <Image src={`cms/navigationAssets/${encodeURIComponent(imageUrl)}`} alt={imageUrl} />;
  };

  const closeSidenav = (): void => {
    dispatch(sideNavigationActions.setBrandsOpen(false));
    dispatch(sideNavigationActions.setVisible(false));
  };

  const openPrizes = (): void => {
    closeSidenav();
    rsiApi.trigger(rsiApi.getEvent('OPEN_ACCOUNT_MENU'));
  };

  const isActive = (item: LinkImageItem): boolean => {
    if (!page) {
      return false;
    }

    return item.link.includes(page);
  };

  const handleBadgeClick = (
    item: BadgeItem,
    badgeCondition?: BadgeVisibilityConditional | null,
  ): void => {
    RSIEventBus.publish(item.onClickEventName, badgeCondition?.returnData);
  };

  const trackClick = (type: ItemTypes, link?: string): void => {
    const event = {
      category: 'Side navigation',
      action: 'click',
      label: `${type} ${link}`,
    };

    RSIAnalytics.event(event);
    logger.info(`Side navigation ${type} click event`, event);
  };

  useEffect(() => {
    if (itemConfig.type === ItemTypes.Badge) {
      const subscriber = RSIEventBus.subscribe(
        itemConfig.badgeEventName,
        (value: BadgeReceiveEvent | null) => {
          setBadgeValue(value);
        },
      );

      return (): void => {
        subscriber.unsubscribe();
      };
    }
    return () => { };
  }, [itemConfig]);

  return (
    <>
      {itemConfig.type === ItemTypes.Link && (
        <SideNavigationItemContainer
          noImage={
            (!itemConfig.image || !isValidImage(itemConfig.image)) && !itemConfig.customImage
          }
        >
          <SideNavigationLink
            onClick={(): void => {
              trackClick(itemConfig.type, itemConfig.link);
              closeSidenav();
            }}
            href={itemConfig.link}
            rel={itemConfig.externalUrl ? 'noreferrer nofollow' : ''}
            target={itemConfig.externalUrl ? '_blank' : ''}
            active={isActive(itemConfig)}
          >
            {getImage(itemConfig)}

            <LinkText>
              <Translate id={itemConfig.translationKey} />
            </LinkText>

            {itemConfig.externalUrl && (
              <ExternalImage
                src="cms/navigationAssets/external.svg"
                alt="external"
              />
            )}
          </SideNavigationLink>
        </SideNavigationItemContainer>
      )}

      {itemConfig.type === ItemTypes.Separator && (
        <Separator offsetted={!itemConfig.fullWidth} />
      )}

      {itemConfig.type === ItemTypes.Logout && (
        <SideNavigationItemContainer
          noImage={
            (!itemConfig.image || !isValidImage(itemConfig.image)) && !itemConfig.customImage
          }
        >
          <SideNavigationButton
            onClick={(): void => {
              trackClick(itemConfig.type);
              closeSidenav();
              logout();
            }}
          >
            {getImage(itemConfig)}
            <LinkText>
              <Translate id={itemConfig.translationKey} />
            </LinkText>
          </SideNavigationButton>
        </SideNavigationItemContainer>
      )}

      {itemConfig.type === ItemTypes.Prizes && (
        <SideNavigationItemContainer
          noImage={
            (!itemConfig.image || !isValidImage(itemConfig.image)) && !itemConfig.customImage
          }
        >
          <SideNavigationButton
            onClick={(): void => {
              trackClick(itemConfig.type);
              openPrizes();
            }}
            data-target="menu-user-bonus"
          >
            {getImage(itemConfig)}
            <LinkText>
              <BadgedText badge={tokens.totalTokens}>
                <Translate id={itemConfig.translationKey} />
              </BadgedText>
            </LinkText>
          </SideNavigationButton>
        </SideNavigationItemContainer>
      )}

      {itemConfig.type === ItemTypes.Badge && (
        <SideNavigationItemContainer
          noImage={
            (!itemConfig.image || !isValidImage(itemConfig.image)) && !itemConfig.customImage
          }
        >
          <SideNavigationButton
            onClick={(): void => handleBadgeClick(itemConfig, activeBadgeCondition)}
          >
            {getImage(itemConfig)}
            <LinkText>
              <BadgedText
                badge={badgeValue?.value || null}
                badgeImage={activeBadgeCondition?.iconPath || null}
              >
                <Translate id={itemConfig.translationKey} />
              </BadgedText>
            </LinkText>
          </SideNavigationButton>
        </SideNavigationItemContainer>
      )}

      {itemConfig.type === ItemTypes.BottomSheetModal && (
        <SideNavigationItemContainer
          noImage={
            (!itemConfig.image || !isValidImage(itemConfig.image)) && !itemConfig.customImage
          }
        >
          <SideNavigationButton
            onClick={(): void => {
              trackClick(itemConfig.type);
              openBottomSheetModal(itemConfig.event.params);
              closeSidenav();
            }}
          >
            {getImage(itemConfig)}
            <LinkText>
              <Translate id={itemConfig.translationKey} />
            </LinkText>
          </SideNavigationButton>
        </SideNavigationItemContainer>
      )}
    </>
  );
};

export default SideNavigationRow;
