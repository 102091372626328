import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../interfaces';
import { setPlayerBalance, setPlayerNewBalance } from '../store/actions/player';
import useConfigs from './useConfigs';
import { eventBus } from '../services';
import { BalanceUpdate, OldBalance } from '../interfaces/player';

export default (): void => {
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);
  const rsiApiLoaded = useSelector((state: RootState) => state.application.rsiApiLoaded);
  const loggedIn = useSelector((state: RootState) => state.player.loggedIn);
  const { cageConf } = useConfigs(['cageConf']);

  // update bonus bar with bonusHandler
  const updateBalance = (): void => {
    const balance = rsiApi.getBalance();
    dispatch(setPlayerBalance(balance));
  };

  // set up listener
  const initializeBalance = (): void => {
    rsiApi.on(rsiApi.getEvent('BALANCE'), (balance: OldBalance) => {
      dispatch(setPlayerBalance(balance));
    });

    setInitialized(true);
  };

  const initializeNewBalance = (): void => {
    eventBus.subscribe(
      eventBus.eventTypes.BALANCE_UPDATE,
      (balance: BalanceUpdate) => {
        dispatch(setPlayerNewBalance(balance));
      },
    );
  };

  useEffect(() => {
    if (!rsiApiLoaded || !cageConf) {
      return;
    }

    if (!cageConf.useDataBalance) {
      initializeBalance();
    } else {
      initializeNewBalance();
    }
    // eslint-disable-next-line
  }, [rsiApiLoaded, cageConf]);

  useEffect(() => {
    if (!initialized) {
      return;
    }

    updateBalance();
    // eslint-disable-next-line
  }, [initialized, loggedIn]);
};
