export default {
  SET_MOBILE_PORTRAIT: 'application/SET_MOBILE_PORTRAIT',
  SET_LANDSCAPE: 'application/SET_LANDSCAPE',
  SET_SCREEN_WIDTH_TYPE: 'application/SET_SCREEN_WIDTH_TYPE',
  SET_PAGE: 'application/SET_PAGE',
  SET_VIEW_TYPE: 'application/SET_VIEW_TYPE',
  SET_SPORTS_LOCATION: 'application/SET_SPORTS_LOCATION',
  SET_RSIAPI_STATUS: 'application/SET_RSIAPI_STATUS',
  SET_VIEWARGS_STATUS: 'application/SET_VIEWARGS_STATUS',
  SET_SUPPORT_CHAT_OPEN_STATUS: 'application/SET_SUPPORT_CHAT_OPEN_STATUS',
  SET_PLAYER_CHAT_OPEN_STATUS: 'application/SET_PLAYER_CHAT_OPEN_STATUS',
  SET_GAMING_MODE: 'application/SET_GAMING_MODE',
  SET_SHOW_GAME_TOP_BAR: 'application/SET_SHOW_GAME_TOP_BAR',
};
