import styled, { css } from 'styled-components';

interface ContainerScrollWrapperProps {
  fullWidth: boolean;
}

const ContainerScrollWrapper = styled.div<ContainerScrollWrapperProps>`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  width: calc(100% - 65px);

  ${(props) => props.fullWidth && css`
    width: 100%;
  `}
`;

export default ContainerScrollWrapper;
