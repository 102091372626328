import { createGlobalStyle } from 'styled-components';

// stylelint-disable selector-max-id,max-nesting-depth, order/order
const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }

  body {
    height: auto !important;
    min-height: 100%;
  }

  #rsi-navigation,
  #rsi-navigation :before,
  #rsi-navigation :after {
    font-family: 'DM Sans';
  }
`;

export default GlobalStyle;
