import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logger } from '../services';
import { applicationActions } from '../store/actions';
import { isClient } from '../utils';

export default (): void => {
  const dispatch = useDispatch();

  let retryCount = 0;

  const initializeRsiApi = (): void => {
    try {
      if (!rsiApi.isLoaded()) {
        retryCount += 1;
        logger.warn(`[rsiApi] rsiApi not loaded, retry ${retryCount}`);
        setTimeout(() => {
          initializeRsiApi();
        }, 1000);
        return;
      }

      dispatch(applicationActions.setRsiApiStatus(true));

      logger.info('[rsiApi] rsiApi ready');
    } catch (e) {
      retryCount += 1;
      logger.warn(`[rsiApi] Cannot find rsiApi, retry ${retryCount}`);
      setTimeout(() => {
        initializeRsiApi();
      }, 1000);
    }
  };

  useEffect(() => {
    if (!isClient) {
      return;
    }

    initializeRsiApi();
    // eslint-disable-next-line
  }, []);
};
