import styled, { css } from 'styled-components';

// stylelint-disable order/order

export interface CurrentBrandProps {
  open?: boolean;
}

interface BrandItemProps {
  active: boolean;
  group: boolean;
  indentLevel?: number;
}

interface BrandItemBrandProps {
  active: boolean;
}

interface BrandHeaderProps {
  noCursor: boolean;
}

export const BrandHeader = styled.div<BrandHeaderProps>`
  display: flex;
  flex-direction: column;
  padding: 0;
  align-items: center;
  margin-right: 10px;
  user-select: none;

  ${(props) => !props.noCursor && css`
    cursor: pointer;
  `}
`;

export const CurrentBrand = styled.div<CurrentBrandProps>`
  display: flex;
  color: ${(props) => props.theme.themed.foreground.base};
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  transition: color 180ms ease;

  ${(props) => props.open && css`
    color: ${props.theme.themed.foreground.linkActive};
  `};
`;

export const BrandContainer = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const BrandSelect = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 230px;
  max-height: calc(100vh - 35px);
  overflow: auto;
  background-color: ${(props) => props.theme.themed.background.secondary};
`;

const BrandItem = css<BrandItemProps>`
  position: relative;
  display: block;
  padding: 16px;
  color: ${(props) => props.theme.themed.foreground.linkColor};
  font-size: 14px;
  font-weight: 500;
  text-decoration: none !important;

  ${(props) => props.indentLevel && css`
    padding-left: ${(props.indentLevel * 16) + 16}px;
  `};

  ${(props) => css`
    &:focus,
    &:hover {
      text-decoration: none !important;
      color: ${props.theme.themed.foreground.linkHover} !important;
    }

    ${props.active && css`
      color: ${props.theme.themed.foreground.linkActive} !important;
      font-weight: 700;

      &:focus,
      &:hover {
        color: ${props.theme.themed.foreground.linkActive} !important;
      }

      &:hover:after {
        content: none;
      }
    `};

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      border-radius: inherit;
      opacity: 0;
      transition: opacity 200ms, background-color 200ms;
    }

    &:hover:after {
      opacity: .04;
      background-color: ${props.theme.themed.foreground.linkHover};
    }

    ${props.active && css`
      color: ${props.theme.themed.foreground.linkActive} !important;
      font-weight: 700;

      &:focus,
      &:hover {
        color: ${props.theme.themed.foreground.linkActive} !important;
      }

      &:hover:after {
        content: none;
      }
    `};
  `};
`;

export const BrandItemLink = styled.a<BrandItemProps>`
  ${BrandItem};
`;

export const BrandItemButton = styled.div<BrandItemProps>`
  ${BrandItem};
`;

export const BrandItemGroup = styled.div``;

export const BrandItemBrand = styled.span<BrandItemBrandProps>`
  margin-left: 4px;
  font-size: 12px;
  opacity: .5;
  ${(props) => props.active && css`
    opacity: .75;
  `};
`;
