import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { RootState } from '../interfaces';
import { applicationActions } from '../store/actions';

/**
 * This is a temporary hook for game window feature flag whether the new top bar needs to be
 * displayed in gaming mode or not. This hook can be removed and replaced with regular isGamingMode
 * when the feature flag gets removed.
 */
export default (): void => {
  const dispatch = useDispatch();
  const gameWindow = useSelector((state: RootState) => state.configs.gameWindow);
  const isGamingMode = useSelector((state: RootState) => state.application.isGamingMode);

  useEffect(() => {
    const showGameTopBar = isGamingMode && gameWindow !== null
      && gameWindow.enableNewTopNavigation.includes(RSIUtils.detector.detailedDeviceType);
    dispatch(applicationActions.setShowGameTopBar(showGameTopBar));
  }, [dispatch, gameWindow, isGamingMode]);
};
