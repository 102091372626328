import { AnyAction } from 'redux';
import { betslipActionTypes } from '../actionTypes';
import { BetslipState } from '../../interfaces';

const initialState: BetslipState = {
  itemsCount: 0,
  isMaximized: false,
  isEnabled: true,
};

export default (state = initialState, action: AnyAction): BetslipState => {
  switch (action.type) {
    case betslipActionTypes.SET_ITEMS_COUNT:
      return {
        ...state,
        itemsCount: action.payload,
      };
    case betslipActionTypes.SET_IS_MAXIMIZED:
      return {
        ...state,
        isMaximized: action.payload,
      };
    case betslipActionTypes.SET_IS_ENABLED:
      return {
        ...state,
        isEnabled: action.payload,
      };
    default:
      return state;
  }
};
