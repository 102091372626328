import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Button, { ButtonLink } from '../rsi/Button';
import { actionHandlers } from '../../store/actions';
import Icon from '../SideNavigation/Icon';
import { RootState, RsiApiEventTypes } from '../../interfaces';
import {
  filterConfigItemsAllowed,
  openBottomSheetModal,
  sendUserEngagementDataForClickEvents,
  togglePlayerChatWindow,
} from '../../utils';
import { ItemTypes, LinkItem } from '../../interfaces/bottomNavigation';
import Translate from '../Translate';

const StyledIcon = styled(Icon)`
  margin-bottom: 5px;
`;

const Widgets: React.FC = () => {
  const { bottomNavigation } = useSelector((state: RootState) => state.configs);
  const { loggedIn } = useSelector((state: RootState) => state.player);
  const {
    isLandscape,
    isGamingMode,
    viewType,
    sportsLocation,
    isSupportChatOpen,
    isPlayerChatOpen,
    rsiApiLoaded,
    page,
  } = useSelector((state: RootState) => state.application);
  const isBetslipMaximized = useSelector(
    (state: RootState) => state.betslip.isMaximized,
  );
  const bottomNavigationItems = filterConfigItemsAllowed(
    bottomNavigation?.items || [],
    loggedIn,
    isGamingMode,
    viewType,
    isLandscape,
  );

  const isActive = (item: LinkItem): boolean => {
    if (!page || !item.link.includes(page)) {
      return false;
    }

    const linkHash = item.link.split('#')[1];
    const currentHash = sportsLocation.split('#')[1] || '';

    if (linkHash && currentHash.includes(linkHash)) {
      return true;
    }

    const hasMorePageItems = bottomNavigationItems.some(
      (navigationItem) => navigationItem.type === ItemTypes.Link
        && navigationItem.link.includes(page)
        && navigationItem !== item
        && currentHash.includes(navigationItem.link.split('#')[1]),
    );

    return !linkHash && !hasMorePageItems;
  };

  const fireEvent = (event: RsiApiEventTypes): void => {
    actionHandlers.handleRsiApiTrigger({
      event,
    }, rsiApiLoaded);
  };

  const handleLinkClick = async (e: React.MouseEvent, target: string): Promise<void> => {
    e.preventDefault();

    if (isBetslipMaximized && window.RSIKambiController) {
      RSIKambiController.setBetslipMaximized(false);
    }

    if (isSupportChatOpen) {
      fireEvent(RsiApiEventTypes.HideSupportChatWindow);
    }

    if (isPlayerChatOpen) {
      togglePlayerChatWindow();
    }

    if (!page || !target.includes(page)) {
      // PRM-3206 - this is unnecessary, RSINavigationHandler intercepts the links currently
      // RSINavigationHandler.performNavigation(navigateLocation);
    }

    sendUserEngagementDataForClickEvents('Bottom Navigation', target);
  };

  const handleSupportChatClick = () => {
    if (isPlayerChatOpen) {
      togglePlayerChatWindow();
    }
    fireEvent(RsiApiEventTypes.ToggleSupportChatWindow);
    sendUserEngagementDataForClickEvents('Bottom Navigation', 'supportChat');
  };

  const handlePlayerChatClick = () => {
    if (isSupportChatOpen) {
      fireEvent(RsiApiEventTypes.HideSupportChatWindow);
    }
    togglePlayerChatWindow();
    sendUserEngagementDataForClickEvents('Bottom Navigation', 'chat');
  };

  const handlePromotionClick = (params: any) => {
    openBottomSheetModal(params);
    sendUserEngagementDataForClickEvents('Bottom Navigation', 'promotion');
  };

  return (
    <>
      {bottomNavigationItems.map((item, i): JSX.Element | null => {
        if (item.type === ItemTypes.Link) {
          const active = !isSupportChatOpen && !isPlayerChatOpen && isActive(item);

          /* eslint-disable react/no-array-index-key */
          return (
            <ButtonLink
              icon
              key={item.type + i}
              active={active}
              href={item.link}
              data-testid={item.translationKey}
              data-test-type={item.type}
              onClick={(e: React.MouseEvent): Promise<void> => handleLinkClick(e, item.link)}
            >
              <StyledIcon
                height={26}
                width={26}
                src={`cms/navigationAssets/${active ? item.activeImage : item.image}`}
              />
              <Translate id={item.translationKey} />
            </ButtonLink>
          );
        }

        if (item.type === ItemTypes.Support) {
          return (
            <Button
              icon
              key={item.type}
              active={isSupportChatOpen}
              onClick={handleSupportChatClick}
              data-testid={item.translationKey}
            >
              <StyledIcon
                height={26}
                width={26}
                src={`cms/navigationAssets/${isSupportChatOpen ? item.activeImage : item.image}`}
              />
              <Translate id={item.translationKey} />
            </Button>
          );
        }

        if (item.type === ItemTypes.PlayerChat) {
          return (
            <Button
              icon
              key={item.type}
              active={isPlayerChatOpen}
              onClick={handlePlayerChatClick}
              data-testid={item.translationKey}
            >
              <StyledIcon
                height={26}
                width={26}
                src={`cms/navigationAssets/${isPlayerChatOpen ? item.activeImage : item.image}`}
              />
              <Translate id={item.translationKey} />
            </Button>
          );
        }

        if (item.type === ItemTypes.CasinoQuickLaunch) {
          return (
            <Button
              className="deny-game-list-close"
              icon
              key={item.type}
              active={isGamingMode}
              onClick={(): void => fireEvent(RsiApiEventTypes.ToggleCasinoQuickLaunch)}
              data-testid={item.translationKey}
            >
              <StyledIcon
                height={26}
                width={26}
                src={`cms/navigationAssets/${isGamingMode ? item.activeImage : item.image}`}
              />
              <Translate id={item.translationKey} />
            </Button>
          );
        }

        if (item.type === ItemTypes.BottomSheetModal) {
          return (
            <Button
              icon
              key={item.type + i}
              onClick={(): void => handlePromotionClick(item.event.params)}
              data-testid={item.translationKey}
            >
              <StyledIcon
                height={26}
                width={26}
                src={`cms/navigationAssets/${item.image}`}
              />
              <Translate id={item.translationKey} />
            </Button>
          );
        }

        return null;
      })}
    </>
  );
};

export default Widgets;
