import styled, { css } from 'styled-components';

interface SideNavigationLinkProps {
  active?: boolean;
  noActiveBackground?: boolean;
}

const LinkItem = css<SideNavigationLinkProps>`
  display: flex;
  flex-basis: 100%;
  align-items: center;
  text-decoration: none;
  color: ${(props) => props.theme.themed.foreground.linkColor};
  padding: 11px 15px 11px 10px;
  transition: background-color 140ms;
  border: 0;
  border-radius: 5px 0 0 5px;
  font-size: 14px;
  font-weight: 500;
  outline: 0;
  background-color: transparent;
  text-align: left;

  ${(props) => props.active && css`
    ${!props.noActiveBackground && css`
      background-color: ${props.theme.themed.background.linkHover} !important;
    `};
    text-decoration: none !important;
    color: ${props.theme.themed.foreground.linkActive};
    font-weight: 700;
  `};

  &:active,
  &:focus,
  &:hover {
    ${(props) => !props.active && css`
      background-color: ${props.theme.themed.background.linkHover} !important;
      text-decoration: none !important;
      color: ${props.theme.themed.foreground.linkHover} !important;
    `};

    ${(props) => props.active && css`
      color: ${props.theme.themed.foreground.linkActive} !important;
    `};
  }
`;

export const SideNavigationLink = styled.a<SideNavigationLinkProps>`
  ${LinkItem};
`;

export const SideNavigationButton = styled.button<SideNavigationLinkProps>`
  ${LinkItem};
`;

export const SideNavigationLinkBrand = styled.span<SideNavigationLinkProps>`
  margin-left: 4px;
  font-size: 12px;
  opacity: .5;
  ${(props) => props.active && css`
    opacity: .75;
    font-weight: 500;
  `};
`;
