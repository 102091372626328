import React from 'react';
import SideNavigation from './SideNavigation/SideNavigation';
import { useTokens } from '../hooks';

function SideNavigationContainer() {
  useTokens();

  return <SideNavigation />;
}

export default SideNavigationContainer;
