import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { RootState } from '../interfaces';
import { getTopNavHeight } from '../utils';

export default (): void => {
  const screenWidthType = useSelector((state: RootState) => state.application.screenWidthType);
  const showGameTopBar = useSelector((state: RootState) => state.application.showGameTopBar);

  const [topBarHeight, setTopBarHeight] = useState<number | null>(null);

  useEffect(() => {
    const newHeight = getTopNavHeight(screenWidthType, showGameTopBar);

    if (topBarHeight === newHeight) {
      return;
    }

    setTopBarHeight(newHeight);
    RSIEventBus.publish(
      RSIEventBus.eventTypes.RSI_TOP_NAVIGATION_HEIGHT_CHANGE,
      newHeight,
      true,
    );
    // eslint-disable-next-line
  }, [screenWidthType, showGameTopBar, topBarHeight]);
};
