import * as React from 'react';
import { useSelector } from 'react-redux';

import PlayerBalance from '../../Player/PlayerBalance';
import Invitation from '../../Invitation/Invitation';
import PlayerBonus from '../../Player/PlayerBonus';
import PlayerProfile from '../../Player/PlayerProfile';
import ChatButton from '../../Player/ChatButton';
import Authentication from '../../rsi/Authentication';

import { HeaderRightContainer } from '../../rsi/Header';
import { RootState } from '../../../interfaces';

const HeaderRight: React.FC = () => {
  const { hasInvite } = useSelector((state: RootState) => state.invite);
  const { topNavigation } = useSelector((state: RootState) => state.configs);
  const hideDropdownArrow = topNavigation?.showMobileDropdownArrows === false;

  return (
    <HeaderRightContainer>
      <PlayerBalance
        compact
        hideDropdownArrow={hideDropdownArrow}
      />
      {hasInvite && (
        <Invitation compact />
      )}
      {!hasInvite && (
        <PlayerBonus
          compact
          hideDropdownArrow={hideDropdownArrow}
        />
      )}
      <PlayerProfile
        compact
        hideDropdownArrow={hideDropdownArrow}
      />
      <ChatButton />
      <Authentication />
    </HeaderRightContainer>
  );
};

export default HeaderRight;
