import {
  PlatformVisibility,
  ProductVerticalVisibility,
  RsiEventParams,
  UserStatusVisibility,
} from '.';

export interface BottomNavigationState {
  visible: boolean;
}

export interface BottomNavigationConfig {
  items: ConfigItem[];
}

export enum ItemTypes {
  Link = 'LINK',
  Support = 'SUPPORT',
  PlayerChat = 'PLAYER_CHAT',
  Betslip = 'BETSLIP',
  CasinoQuickLaunch = 'CASINO_QUICK_LAUNCH',
  BottomSheetModal = 'BOTTOM_SHEET_MODAL',
}

export type ConfigItem =
  | LinkItem
  | BetslipItem
  | BottomSheetModalItem
  | BaseBottomNavigationItem<ItemTypes.Support | ItemTypes.PlayerChat>;

interface BaseBottomNavigationItem<T> {
  type: T;
  image: string;
  activeImage: string;
  translationKey: string;
  products: ProductVerticalVisibility;
  platforms: PlatformVisibility;
  conditional: UserStatusVisibility;
}

export interface LinkItem extends BaseBottomNavigationItem<ItemTypes.Link> {
  link: string;
  externalUrl: boolean;
}

// eslint-disable-next-line max-len
export interface BetslipItem extends BaseBottomNavigationItem<ItemTypes.Betslip | ItemTypes.CasinoQuickLaunch> {
  disabledImage: string;
}

export interface BottomSheetModalItem extends BaseBottomNavigationItem<ItemTypes.BottomSheetModal> {
  event: { params: RsiEventParams };
}
