import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { betslipActions } from '../store/actions';
import { isClient } from '../utils';

export default (): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isClient) {
      return;
    }

    let kambiControllerUnsubscribe: any;
    if (window.RSIKambiController) {
      kambiControllerUnsubscribe = RSIKambiController.subscribe(({ betslip }: any) => {
        dispatch(betslipActions.setItemsCount(betslip.selectedOutcomes.length));
        dispatch(betslipActions.setIsMaximized(betslip.maximized));
        dispatch(betslipActions.setIsEnabled(betslip.isEnabled));
      });
    }

    // eslint-disable-next-line consistent-return
    return (): void => {
      if (kambiControllerUnsubscribe) {
        kambiControllerUnsubscribe();
      }
    };
  }, [dispatch]);
};
