import { PlatformVisibility, UserStatusVisibility } from './ConfigsState';

export interface TopNavigationState {
  visible: boolean;
}

export interface TopNavigationConfig {
  showMobileDropdownArrows?: boolean;
  items: ConfigItem[];
}

export type ConfigItem =
  ProductItem
  | ImageItem
  | LinkItem
  | BaseTopNavigationItem;

export enum ItemTypes {
  Burger = 'BURGER',
  Logo = 'LOGO',
  Sportsbook = 'SPORTSBOOK',
  Casino = 'CASINO',
  LiveCasino = 'LIVE_CASINO',
  Poker = 'POKER',
  CasinoQuickLaunch = 'CASINO_QUICK_LAUNCH',
  Cashier = 'CASHIER',
  Bonus = 'BONUS',
  Bingo = 'BINGO',
  Tournament = 'TOURNAMENT',
  MyAccount = 'MY_ACCOUNT',
  Chat = 'CHAT',
  Login = 'LOGIN',
  Signup = 'SIGNUP',
  Link = 'LINK',
}

export interface BaseTopNavigationItem<T = Exclude<
  ItemTypes,
  ItemTypes.Casino | ItemTypes.Sportsbook | ItemTypes.Poker | ItemTypes.CasinoQuickLaunch
>> {
  type: T;
  conditional: UserStatusVisibility;
  translationKey: string;
}

export interface ImageItem<T = ItemTypes.CasinoQuickLaunch> extends BaseTopNavigationItem<T> {
  image: string;
  platforms: PlatformVisibility;
}

export interface ProductItem<T = ItemTypes.Casino | ItemTypes.Sportsbook | ItemTypes.Poker>
  extends ImageItem<T> {
  link: string;
}

export interface LinkItem<T = ItemTypes.Link> extends BaseTopNavigationItem<T> {
  link: string;
  customImage: string;
  externalUrl: boolean;
}
