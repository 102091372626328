import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../interfaces';
import BottomNavigation from './BottomNavigation/BottomNavigation';
import BetslipStyle from './rsi/BetslipStyle';
import eventBus from '../services/eventBus';

/**
 * Component renders the HTML content only in android app and in mobile web layout. Visual bottom
 * bar itself will not be displayed when in-game top bar comes visible.
 */
function BottomNavigationContainer() {
  const showGameTopBar = useSelector((state: RootState) => state.application.showGameTopBar);
  const isMobilePortrait = useSelector((state: RootState) => state.application.isMobilePortrait);
  const isBetslipEnabled = useSelector((state: RootState) => state.betslip.isEnabled);
  const [showBottomBar, setShowBottomBar] = useState<boolean>(false);

  useEffect(() => {
    const hasBottomNav = !showGameTopBar && isMobilePortrait;
    setShowBottomBar(hasBottomNav);
  }, [showGameTopBar, isMobilePortrait]);

  useEffect(() => {
    eventBus.publish(
      eventBus.eventTypes.RSI_BOTTOM_NAVIGATION_HEIGHT_CHANGE,
      showBottomBar ? 65 : 0,
      {
        withHistory: true,
        toNative: true,
      },
    );
  }, [showBottomBar]);

  useEffect(() => () => {
    eventBus.publish(
      eventBus.eventTypes.RSI_BOTTOM_NAVIGATION_HEIGHT_CHANGE,
      0,
      {
        withHistory: true,
        toNative: true,
      },
    );
  }, []);

  return (
    <>
      <BetslipStyle hide={!isBetslipEnabled} />
      {showBottomBar && <BottomNavigation />}
    </>
  );
}

export default BottomNavigationContainer;
