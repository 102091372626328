import { PlatformVisibility, UserStatusVisibility } from './ConfigsState';
import { RsiEventParams } from './rsiEventBus';

export interface CompactNavigationState {
  show: boolean;
}

export interface CompactNavigationConfig {
  items: ConfigItem[];
}

export type ConfigItem = LinkItem | SupportItem | BottomSheetModalItem;

export enum ItemTypes {
  Link = 'LINK',
  Support = 'SUPPORT',
  BottomSheetModal = 'BOTTOM_SHEET_MODAL',
}

interface BaseCompactNavigationItem<T> {
  type: T;
  image: string;
  translationKey: string;
  platforms: PlatformVisibility;
  conditional: UserStatusVisibility;
}

export interface LinkItem extends BaseCompactNavigationItem<ItemTypes.Link> {
  link: string;
  externalUrl: boolean;
}

export type SupportItem = BaseCompactNavigationItem<ItemTypes.Support>;

export interface BottomSheetModalItem extends BaseCompactNavigationItem
  <ItemTypes.BottomSheetModal> {
  event: { params: RsiEventParams };
}
