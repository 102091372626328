import { useEffect, useState } from 'react';

import useConfigs from './useConfigs';

const useTranslations = (translationFilename: string): Record<string, string> | undefined => {
  const [translations, setTranslations] = useState();
  const { language } = useConfigs(['language']);

  /**
   * Workaround to prevent fetching the Translations before the language config.
   */
  useEffect(() => {
    let unsubscribe: () => void;
    if (language) {
      const configKey = `${translationFilename}-${language.default}`;
      unsubscribe = RSIConfigHandler.subscribe(
        [{
          configKey,
        }],
        ({ data }) => {
          setTranslations(data);
        },
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [language, translationFilename]);
  return translations;
};

export default useTranslations;
