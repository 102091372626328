import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../interfaces';
import { playerActions } from '../store/actions';
import { logger } from '../services';

export default (): void => {
  const dispatch = useDispatch();
  const rsiApiLoaded = useSelector((state: RootState) => state.application.rsiApiLoaded);

  const updateCustomTokens = (summedCount?: number) => {
    let count: number | null;
    if (typeof summedCount === 'number') {
      count = summedCount;
    } else {
      try {
        const tokenHandler = rsiApi.getModule('handlers/TokenHandler');
        count = tokenHandler.getTokenCount();
      } catch (e) {
        count = null;
        logger.warn('Failed getting custom tokens');
      }
    }

    dispatch(playerActions.setPlayerCustomTokens(count));
  };

  const updateLoyaltyTokens = (summedCount?: number) => {
    let count: number | null;
    if (typeof summedCount === 'number') {
      count = summedCount;
    } else {
      try {
        if (!rsiApi.hasModule('handlers/LoyaltySpinHandler')) {
          count = null;
          logger.warn('No loyalty spinner module available');
        } else {
          const tokenHandler = rsiApi.getModule('handlers/LoyaltySpinHandler');
          count = tokenHandler.getTokens().length;
        }
      } catch (e) {
        count = null;
        logger.warn('Failed getting loyalty tokens');
      }
    }

    dispatch(playerActions.setPlayerLoyaltyTokens(count));
  };

  const initializeTokensChange = (): void => {
    updateCustomTokens();
    updateLoyaltyTokens();
    rsiApi.on(rsiApi.getEvent('CUSTOM_TOKEN_COUNT'), (summedCount) => {
      updateCustomTokens(summedCount);
    });
    rsiApi.on(rsiApi.getEvent('LOYALTY_TOKEN_COUNT'), (summedCount) => {
      updateLoyaltyTokens(summedCount);
    });
  };

  useEffect(() => {
    if (!rsiApiLoaded) {
      return;
    }

    initializeTokensChange();

    // eslint-disable-next-line
  }, [rsiApiLoaded]);
};
