/* eslint-disable import/prefer-default-export */
import { AxiosInstance, AxiosResponse } from 'axios';
import { AnyAction } from 'redux';
import { configActionTypes } from '../actionTypes';

export const getNavigationConfig = (): AnyAction => ({
  type: configActionTypes.GET_NAVIGATION_CONFIG_REQUEST,
  useApi: (client: AxiosInstance): Promise<AxiosResponse> => client.get(`./conf/navigation.json?v=${parseInt((new Date().getTime() / 1000000).toString(), 10)}`),
});

export const getLoyaltyLevelsConfig = (): AnyAction => ({
  type: configActionTypes.GET_LOYALTY_LEVELS_CONFIG_REQUEST,
  useApi: (client: AxiosInstance): Promise<AxiosResponse> => client.get(`./conf/navLoyaltyLevelColors.json?v=${parseInt((new Date().getTime() / 1000000).toString(), 10)}`),
});

export const getSiteSelectConfig = (): AnyAction => ({
  type: configActionTypes.GET_SITE_SELECT_CONFIG_REQUEST,
  useApi: (client: AxiosInstance): Promise<AxiosResponse> => client.get(`./conf/siteSelect.json?v=${parseInt((new Date().getTime() / 1000000).toString(), 10)}`),
});

export const getTournamentsConfig = (): AnyAction => ({
  type: configActionTypes.GET_TOURNAMENTS_CONFIG_REQUEST,
  useApi: (client: AxiosInstance): Promise<AxiosResponse> => client.get(`./conf/tournaments.json?v=${parseInt((new Date().getTime() / 1000000).toString(), 10)}`),
});
