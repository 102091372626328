import styled from 'styled-components';
import Header from '../../rsi/Header';
import breakpoints from '../../../constants/breakpoints';
import { Link } from '../Desktop/HeaderLink';

const MobileHeader = styled(Header)`
  height: 60px;
  padding-left: 10px;
  padding-right: 10px;

  @media (max-width: 340px) {
    padding-left: 4px;
    padding-right: 4px;
  }
`;

export const MobileHeaderLink = styled.a`
  ${Link};

  @media (max-width: ${breakpoints.xs}px) {
    min-width: 0;
    padding: 0;
  }
`;

export default MobileHeader;
