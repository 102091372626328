import React, { StrictMode, useEffect } from 'react';
import { createRoot, Root } from 'react-dom/client';
import Module from './lib';
import { InitArgs } from './lib/interfaces';
import { logger } from './lib/services';
import UMDLoader from './lib/UMDLoader';

const {
  REACT_APP_IDENTIFIER,
  PUBLIC_URL,
} = process.env;

const isDevelopment = process.env.NODE_ENV === 'development';
const createsMultiInstanceModule = JSON.parse(
  process.env.REACT_APP_CREATES_MULTI_INSTANCE_MODULE as string,
);
const providesVanillaModule = JSON.parse(process.env.REACT_APP_PROVIDES_VANILLA_MODULE as string);

const canIncludeUMDModule = !providesVanillaModule
  && JSON.parse(process.env.REACT_APP_INCLUDES_CLIENT_FROM_UMD as string);

let sourceUrl = PUBLIC_URL;
if (isDevelopment) {
  sourceUrl = `http://localhost:${process.env.REACT_APP_PORT}`;
}

const version = process.env.REACT_APP_VERSION;

interface ModuleProps extends InitArgs {
  containerId: string;
}

function AppWithCallbackAfterRender({ initialState, containerId }: ModuleProps) {
  useEffect(() => {
    logger.trace('Client initialized', { containerId });
  }, [containerId]);

  return (
    <StrictMode>
      {canIncludeUMDModule ? (
        <UMDLoader
          url={`${sourceUrl}/${REACT_APP_IDENTIFIER}.umd.buildtype.js`}
          name={process.env.REACT_APP_TAG as string}
          props={{ initialState }}
        />
      ) : (
        <Module initialState={initialState} />
      )}
    </StrictMode>
  );
}

const instances = new Map<string, Root>();

const isSelfExecuting = isDevelopment || providesVanillaModule || !createsMultiInstanceModule;

function destroy(id: string) {
  try {
    if (!id && !isSelfExecuting) {
      throw new Error('Client is not single instance, cannot destroy client without container id');
    }

    const containerId = id || REACT_APP_IDENTIFIER as string;

    const destroyTarget = instances.get(containerId);

    if (!destroyTarget) {
      throw new Error('Client not initialized');
    }

    destroyTarget.unmount();

    instances.delete(containerId);

    logger.trace('Client destroyed', { containerId });
  } catch (error) {
    logger.error('Failed to destroy client', { error });
  }
}

interface Args extends InitArgs {
  containerId?: string;
}

function init({ containerId: id, initialState }: Args = {}) {
  try {
    let containerId = id;

    if (!containerId) {
      logger.trace(`containerId not used to init, using default value: ${REACT_APP_IDENTIFIER}`);

      containerId = REACT_APP_IDENTIFIER as string;
    }

    const container = document.getElementById(containerId);

    if (!container) {
      throw new Error(`Could not find container ${containerId} in DOM`);
    }

    if (instances.has(containerId)) {
      throw new Error(`Client already initialized to container ${containerId}`);
    }

    const root = createRoot(container);

    root.render(
      <AppWithCallbackAfterRender
        initialState={initialState}
        containerId={containerId}
      />,
    );

    instances.set(containerId, root);

    return {
      destroy() {
        if (containerId) {
          destroy(containerId);
        }
      },
    };
  } catch (error) {
    logger.error('Failed to initialize client', { error });

    return undefined;
  }
}

if (isSelfExecuting) {
  init({ containerId: REACT_APP_IDENTIFIER as string });
}

export {
  version,
  instances,
  init,
  destroy,
};
