/**
 * Tailwind CSS default breakpoints.
 */
export default {
  xxs: 320,
  xs: 375,
  sm: 640,
  md: 768,
  lg: 992,
};
