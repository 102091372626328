import {
  useTopNavHeight,
} from '../hooks';

/**
 * Most of the custom hooks are functional that just dispatch some events and some use useSelect
 * redux hook (easy to use). That useSelect triggers re-rendering. All these hooks are gathered
 * here with 0 visual component to avoid unnecessary rendering.
 */
function WebGlobalHooksContainer(): null {
  useTopNavHeight();

  return null;
}

export default WebGlobalHooksContainer;
