import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../interfaces';
import { filterConfigItemsAllowed } from '../../utils';
import Navigation from '../rsi/Navigation';
import { BetslipItem, ItemTypes } from '../../interfaces/bottomNavigation';
import Container from '../rsi/Container';
import Widgets from './Widgets';
import ContainerScrollWrapper from '../rsi/ContainerScrollWrapper';
import GlobalBottomNavigationStyle from '../rsi/GlobalBottomNavigationStyle';
import Betslip from './Betslip';
import { setHidden, setVisible } from '../../store/actions/bottomNavigation';

function BottomNavigation() {
  const targetRef = useRef(null);
  const dispatch = useDispatch();
  const bottomNavigation = useSelector((state: RootState) => state.configs.bottomNavigation);
  const loggedIn = useSelector((state: RootState) => state.player.loggedIn);
  const betslipConfig = (filterConfigItemsAllowed(
    bottomNavigation?.items || [],
    loggedIn,
  ).find((item): item is BetslipItem => (
    item.type === ItemTypes.Betslip
  )));

  useEffect(() => {
    dispatch(setVisible());

    return (): void => {
      dispatch(setHidden());
    };
  }, [dispatch]);

  return (
    <div id="rsi-bottom-navigation">
      <GlobalBottomNavigationStyle />
      <Navigation ref={targetRef}>
        <ContainerScrollWrapper fullWidth={!betslipConfig}>
          <Container withScroll>
            <Widgets />
          </Container>
        </ContainerScrollWrapper>
        {betslipConfig && (
          <Container right withSpacer>
            <Betslip betslipConfig={betslipConfig} />
          </Container>
        )}
      </Navigation>
    </div>
  );
}

export default BottomNavigation;
