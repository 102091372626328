import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ProgressCircle from '../Wrappers/ProgressCircle';
import LabeledItem from '../Wrappers/LabeledItem';
import { findConfigItem, isConfigItemAllowed } from '../../utils';
import { RootState } from '../../interfaces';
import { ItemTypes } from '../../interfaces/topNavigation';
import { logger } from '../../services';
import eventBus, { eventTypes } from '../../services/eventBus';

interface PlayerProfileProps {
  compact?: boolean;
  hideDropdownArrow?: boolean
}

const { CLOSE_BONUS_MENU, OPEN_BONUS_MENU } = eventTypes;

// eslint-disable-next-line react/function-component-definition
// @ts-ignore
const PlayerBonus: React.FC<PlayerProfileProps> = ({ compact, hideDropdownArrow }) => {
  const {
    loggedIn,
    bonus,
    profile,
  } = useSelector((state: RootState) => state.player);
  const { topNavigation } = useSelector((state: RootState) => state.configs);
  const { rsiApiLoaded } = useSelector((state: RootState) => state.application);

  const [isOpen, setIsOpen] = useState(false);

  const bonusConfig = findConfigItem(
    topNavigation?.items || [],
    ItemTypes.Bonus,
  );

  const toggleBonusMenu = useCallback((): void => {
    const event = {
      category: 'Top navigation',
      action: 'click',
      label: `${ItemTypes.Bonus} ${isOpen ? 'close' : 'open'}`,
    };

    RSIAnalytics.event(event);
    logger.info(`Top navigation ${ItemTypes.Bonus} click event`, event);

    eventBus.publish(
      isOpen ? CLOSE_BONUS_MENU : OPEN_BONUS_MENU,
      {},
      { toNative: true, withHistory: false },
    );
  }, [isOpen]);

  useEffect(() => {
    if (!rsiApiLoaded) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {
      };
    }

    const openMenuCallback = () => {
      setIsOpen(true);
    };
    const closeMenuCallback = () => {
      setIsOpen(false);
    };

    const openBonusSubscriber = eventBus.subscribe(OPEN_BONUS_MENU, openMenuCallback);
    const closeBonusSubscriber = eventBus.subscribe(CLOSE_BONUS_MENU, closeMenuCallback);

    return () => {
      openBonusSubscriber.unsubscribe();
      closeBonusSubscriber.unsubscribe();
    };
  }, [rsiApiLoaded]);

  return (
    isConfigItemAllowed(bonusConfig, loggedIn) && loggedIn && profile && (
      <LabeledItem
        label={bonusConfig!.translationKey}
        onClick={toggleBonusMenu}
        dataTarget="menu-user-bonus"
        open={false}
        compact={compact}
        noDropdownIcon={hideDropdownArrow}
      >
        <ProgressCircle
          progress={bonus.bar!}
          compact={compact}
          image="cms/navigationAssets/golden-pig.png"
          tinyIcon={bonus.bar !== null ? {
            icon: 'oval.svg',
            content: '$',
          } : undefined}
        />
      </LabeledItem>
    )
  );
};

PlayerBonus.defaultProps = {
  compact: undefined,
  hideDropdownArrow: false,
};

export default PlayerBonus;
