import styled, { css } from 'styled-components';

export interface BrandsSelectProps {
  open: boolean;
}

export interface CurrentBrandProps {
  notClickable: boolean;
}

interface SideNavigationLabelProps {
  dualBrandingEnabled: boolean | null;
}

// width: image width + paddings
export const CloseIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(30px + 15px + 10px);
  padding-left: 10px;
  padding-right: 15px;

  @media (max-width: 340px) {
    padding-left: 5px;
    padding-right: 10px;
  }
`;

export const SideNavigationLabel = styled.span<SideNavigationLabelProps>`
  ${(props) => props.dualBrandingEnabled && css`
    @media (max-width: 340px) {
      max-width: 70px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  `};
`;

export const BrandHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 10px;
  min-height: 55px;
`;

export const CurrentBrandIcon = styled.div`
  display: flex;
  margin-right: 15px;
  height: 35px;

  @media (max-width: 340px) {
    margin-right: 10px;
  }
`;

export const CurrentBrand = styled.div<CurrentBrandProps>`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.themed.foreground.base};
  font-size: 14px;
  font-weight: 700;

  ${(props) => !props.notClickable && css`
    cursor: pointer;
  `}
`;

export const BrandSelect = styled.div<BrandsSelectProps>`
  transition: background-color 200ms ease;
  background-color: ${(props) => props.theme.themed.background.secondary};
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 8px;

  ${(props) => !props.open && css`
    display: none;
  `};
`;

export const DualBrandSeparator = styled.div`
  height: 40%;
  width: 1px;
  background-color: #E8EFF7;
  opacity: .7;
  margin: auto 6px;
`;

interface BrandItemProps {
  active?: boolean;
  noActiveBackground?: boolean;
  group?: boolean;
  indentLevel?: number;
}

interface BrandItemBrandProps {
  active?: boolean;
}

const BrandItem = css<BrandItemProps>`
  display: block;
  flex-basis: 100%;
  align-items: center;
  text-decoration: none;
  color: ${(props) => props.theme.themed.foreground.linkColor};
  padding: 11px 15px 11px 10px;
  transition: background-color 140ms;
  border: 0;
  border-radius: 5px 0 0 5px;
  font-size: 14px;
  font-weight: 500;
  outline: 0;
  background-color: transparent;
  text-align: left;

  ${(props) => props.indentLevel && css`
    padding-left: ${(props.indentLevel * 16) + 10}px;
  `};

  ${(props) => !props.group && css`
    ${props.active && css`
      text-decoration: none !important;
      color: ${props.theme.themed.foreground.linkActive};
      font-weight: 700;
    `};

    &:active,
    &:focus,
    &:hover {
      ${!props.active && css`
        background-color: ${props.theme.themed.background.linkHover} !important;
        text-decoration: none !important;
        color: ${props.theme.themed.foreground.linkHover} !important;
      `};

      ${props.active && css`
        color: ${props.theme.themed.foreground.linkActive} !important;
      `};
    }

  `};
`;

export const BrandItemLink = styled.a<BrandItemProps>`
  ${BrandItem};
`;

export const BrandItemButton = styled.div<BrandItemProps>`
  ${BrandItem};
`;

export const BrandItemGroup = styled.div``;

export const BrandItemBrand = styled.span<BrandItemBrandProps>`
  margin-left: 4px;
  font-size: 12px;
  opacity: .5;
  ${(props) => props.active && css`
    opacity: .75;
    font-weight: 500;
  `};
`;
