import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  useGamingMode,
  useGlobalListeners,
  useInGameTopBar,
  useKambiControllerProxy,
  useKambiWidget,
  usePageType,
  usePlayer,
  useRsiApi,
  useRsiAppEvents,
} from '../hooks';
import { actionHandlers, configActions } from '../store/actions';

/**
 * Most of the custom hooks are functional that just dispatch some events and some use useSelect
 * redux hook (easy to use). That useSelect triggers re-rendering. All these hooks are gathered
 * here with 0 visual component to avoid unnecessary rendering.
 */
function GlobalHooksContainer(): null {
  const dispatch = useDispatch();

  useRsiApi();
  useGamingMode();
  usePageType();
  useGlobalListeners();
  useInGameTopBar();

  // for SPORTS betslip
  useKambiWidget();
  useRsiAppEvents();
  useKambiControllerProxy();
  usePlayer();

  useEffect(() => {
    // @TODO PRM-4668
    const navigationSubscription = RSINavigationHandler.subscribe(() => {
      // @TODO PRM-4516
      // @ts-ignore
      dispatch(actionHandlers.handleLocation());
      // eslint-disable-next-line
      return () => {
        navigationSubscription();
      };
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(configActions.getNavigationConfig());
  }, [dispatch]);

  return null;
}

export default GlobalHooksContainer;
