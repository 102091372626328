import styled, { css } from 'styled-components';
import { fadeInFadeOut } from '../animations';
import { ButtonBaseProps, ButtonStyles } from './Base';

interface ButtonProps extends ButtonBaseProps {
  betslip?: boolean;
  animated?: boolean;
}

const Button = styled.button<ButtonProps>`
  ${ButtonStyles}
  ${(props) => props.betslip && css`
    width: 70px;
    color: ${props.theme.components.betslip.color};
    &:disabled {
      color: ${props.theme.components.betslip.disabled};
    }
  `};
  ${(props) => !props.betslip && css`
    min-width: 55px;
    @media only screen and (max-width: 360px) {
      padding-left: 6px;
      padding-right: 6px;
    }
  `}
  ${(props) => props.animated && css`
    animation: ${fadeInFadeOut(props)} 1.5s linear;
  `}
`;

export const ButtonLink = styled.a<ButtonBaseProps>`
  ${ButtonStyles}

  text-decoration: none !important;
`;

export default Button;
