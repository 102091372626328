import { createGlobalStyle, css } from 'styled-components';
import { ScreenWidthType } from '../../interfaces';
import { getTopNavHeight } from '../../utils';

interface GlobalProps {
  hasTopNav: boolean;
  hasBottomNav: boolean;
  hasGameTopBar: boolean;
  screenWidthType: ScreenWidthType;
  supportChatVisible: boolean;
}

const bottomNavHeight = '65px';

const getChatHeight = (props: GlobalProps) => {
  let reducedHeight = '100%';

  if (props.hasTopNav) {
    const topHeight = getTopNavHeight(props.screenWidthType, props.hasGameTopBar);
    reducedHeight += ` - ${topHeight}px`;
  }

  if (props.hasBottomNav) {
    reducedHeight += ` - ${bottomNavHeight}`;
  }

  return css`
    height: calc(${reducedHeight}) !important;
  `;
};

// stylelint-disable selector-max-id,max-nesting-depth, order/order
const WebWidgetGlobalStyle = createGlobalStyle<GlobalProps>`

  /* stylelint-disable-next-line */
  iframe[data-test-id="ChatWidgetMobileWindow"],
  iframe#webWidget {
    z-index: 2999 !important;
  }

  ${(props) => (props.hasBottomNav || props.hasTopNav) && css`
    iframe#webWidget,
    body.platform-app.platform-android iframe#webWidget,
    body.platform-app.platform-ios iframe#webWidget,
    body.platform-app.platform-ios.force-show-zopim iframe#webWidget {
      ${props.supportChatVisible && css`
        bottom: 0;

        ${props.hasBottomNav && css`
          bottom: ${bottomNavHeight} !important;
        `}
      `}

      ${getChatHeight(props)}
    }
  `}
`;

export default WebWidgetGlobalStyle;
