export const stripUnit = (value: string | number): number | null => {
  const match = /[0-9]+\.?[0-9]*/.exec(value.toString());
  return match ? Number(match[0]) : match;
};

export const getUnit = (value: string): string => {
  const match = /[a-z]+|%/i.exec(value);
  return match ? match[0] : '';
};

export const add = (value: string, addValue: string | number): string | null => {
  const number = stripUnit(value) || 0;
  const unit = getUnit(value) || '';
  const addNumber = stripUnit(addValue) || 0;
  return `${number + addNumber}${unit}`;
};

export const multiply = (multiple: number, value: string): string | null => {
  const number = stripUnit(value) || 0;
  const unit = getUnit(value) || '';
  return `${number * multiple}${unit}`;
};

export const divide = (denominator: number, value: string): string | null => {
  const number = stripUnit(value) || 0;
  const unit = getUnit(value) || '';
  return `${number / denominator}${unit}`;
};
