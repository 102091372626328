import { AnyAction } from 'redux';
import { bottomNavigationActionTypes } from '../actionTypes';
import { BottomNavigationState } from '../../interfaces/bottomNavigation';

const initialState: BottomNavigationState = {
  visible: false,
};

export default (state = initialState, action: AnyAction): BottomNavigationState => {
  switch (action.type) {
    case bottomNavigationActionTypes.SET_HIDDEN:
      return {
        ...state,
        visible: false,
      };
    case bottomNavigationActionTypes.SET_VISIBLE:
      return {
        ...state,
        visible: true,
      };
    default:
      return state;
  }
};
