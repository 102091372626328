import React from 'react';
import DesktopHeader from './Desktop/Header';
import CompactHeader from './Desktop/CompactHeader';

const DesktopNavigation: React.FC = () => (
  <>
    <DesktopHeader />
    <CompactHeader />
  </>
);

export default DesktopNavigation;
