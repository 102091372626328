import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { RootState } from '../interfaces';
import { playerActions } from '../store/actions';
import { eventBus, logger } from '../services';

export default (): void => {
  const dispatch = useDispatch();
  const rsiApiLoaded = useSelector((state: RootState) => state.application.rsiApiLoaded);

  useEffect(() => {
    if (!rsiApiLoaded) {
      return () => { };
    }

    const publicProfile = rsiApi.getModule('account/PublicProfile');
    const playerProfileChange = (profile: any): void => {
      dispatch(playerActions.setPlayerProfile(profile));
    };
    const playerAvatarChange = (): void => {
      if (publicProfile) {
        dispatch(playerActions.setPlayerAvatar(publicProfile.getMyAvatarUrl()));
      }
    };

    let profile: any = null;
    try {
      const { cage } = viewArgs.mainConf;
      const playerProfile = sessionStorage.getItem(`profile-${cage}`);
      profile = playerProfile ? JSON.parse(playerProfile) : null;
    } catch (e) {
      logger.warn('invalid json object in player profile');
    }
    playerProfileChange(profile);
    playerAvatarChange();

    rsiApi.on(rsiApi.getEvent('PLAYER_PUBLIC_PROFILE_UPDATE'), playerProfileChange);
    const playerAvatarChangeSubscription = eventBus.subscribe(
      eventBus.eventTypes.PLAYER_AVATAR_UPDATE,
      playerAvatarChange,
    );

    return () => {
      rsiApi.off(rsiApi.getEvent('PLAYER_PUBLIC_PROFILE_UPDATE'), playerProfileChange);
      playerAvatarChangeSubscription.unsubscribe();
    };
  }, [rsiApiLoaded, dispatch]);
};
