import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PlayerProfile from '../../Player/PlayerProfile';
import LabeledItem from '../../Wrappers/LabeledItem';
import Invitation from '../../Invitation/Invitation';
import Header, { HeaderContentWrapper, HeaderLeftContainer, HeaderRightContainer } from '../../rsi/Header';
import PlayerBalance from '../../Player/PlayerBalance';
import Authentication from '../../rsi/Authentication';
import Icon from '../../SideNavigation/Icon';
import { RootState } from '../../../interfaces';
import PlayerBonus from '../../Player/PlayerBonus';
import { filterConfigItemsAllowed } from '../../../utils';
import { ItemTypes, LinkItem, ProductItem } from '../../../interfaces/topNavigation';
import ProductNavigation from './ProductNavigation';
import { logger } from '../../../services';

const DesktopHeaderContainer = styled(Header)`
  top: 35px;
  height: 70px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .20);
`;

const Logo = styled.a`
  display: flex;
  margin-right: 10px;
`;

const DualBrandSeparator = styled.div`
  height: 18px;
  width: 1px;
  background-color: #E8EFF7;
  opacity: .7;
  margin: auto 8px;
`;

const HeaderLink = styled.a`
  margin-left: 15px;
`;

const LinkIcon = styled(Icon)`
  margin-right: 10px;
`;

const DesktopHeader: React.FC = () => {
  const { topNavigation } = useSelector((state: RootState) => state.configs);
  const { isGamingMode } = useSelector((state: RootState) => state.application);
  const { loggedIn } = useSelector((state: RootState) => state.player);
  const { primary, dual } = useSelector((state: RootState) => state.logo);

  const logoLink = (filterConfigItemsAllowed(
    topNavigation?.items || [],
    loggedIn,
    isGamingMode,
  ).find((item): item is ProductItem => (
    item.type === ItemTypes.Casino || item.type === ItemTypes.Sportsbook
    || item.type === ItemTypes.Poker
  )))?.link || null;

  const customLinks = filterConfigItemsAllowed(
    topNavigation?.items || [],
    loggedIn,
    isGamingMode,
  ).filter((item): item is LinkItem => item.type === ItemTypes.Link);

  const handleLogoClick = (): void => {
    const analyticsEvent = {
      category: 'Top navigation',
      action: 'click',
      label: 'logo',
    };
    RSIAnalytics.event(analyticsEvent);
    logger.info('Top navigation logo click event', analyticsEvent);
  };

  return (
    <DesktopHeaderContainer>
      <HeaderContentWrapper>
        <HeaderLeftContainer>
          <Logo
            onClick={handleLogoClick}
            href={logoLink || ''}
          >
            <Icon
              src={primary}
              height="100%"
              width="100%"
              maxHeight={40}
              maxWidth={100}
            />
            {dual && (
              <>
                <DualBrandSeparator />
                <Icon
                  src={dual}
                  height="100%"
                  width="100%"
                  maxHeight={40}
                  maxWidth={100}
                />
              </>
            )}
          </Logo>
          <ProductNavigation />
        </HeaderLeftContainer>

        <HeaderRightContainer>
          <PlayerBalance />
          <Invitation />
          <PlayerBonus />
          <PlayerProfile />
          <Authentication />

          {customLinks.map((link) => (
            <HeaderLink
              href={link.link}
              rel={link.externalUrl ? 'noreferrer nofollow' : ''}
              target={link.externalUrl ? '_blank' : ''}
            >
              {/*
               * We dont require labels for custom navigation items as they
               * can be just images
               */}
              <LabeledItem
                label={link.translationKey}
                open={false}
                noDropdownIcon
              >
                <LinkIcon
                  src={`cms/navigationAssets/${link.customImage}`}
                  alt={link.customImage}
                  maxHeight={44}
                  maxWidth={44}
                />
              </LabeledItem>
            </HeaderLink>
          ))}
        </HeaderRightContainer>
      </HeaderContentWrapper>
    </DesktopHeaderContainer>
  );
};

export default DesktopHeader;
