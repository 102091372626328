import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import {
  BaseSiteSelectItem, GroupSiteItem, RootState, SiteSelectItem,
} from '../../interfaces';
import { sideNavigationActions } from '../../store/actions';
import { siteSelect } from '../../utils';
import DropdownLabel from '../Wrappers/DropdownLabel';
import {
  BrandHeader,
  BrandItemBrand,
  BrandItemButton,
  BrandItemGroup,
  BrandItemLink,
  BrandSelect,
  CloseIcon,
  CurrentBrand,
  CurrentBrandIcon,
  DualBrandSeparator,
  SideNavigationLabel,
} from './Brand';
import Icon from './Icon';
import Separator from './Separator';
import SideNavigationItemContainer from './SideNavigationItemContainer';
import Translate from '../Translate';

const BrandNavigation: React.FC = () => {
  const dispatch = useDispatch();
  const brands = useSelector((state: RootState) => state.configs.siteSelect?.items || []);
  const config = useSelector((state: RootState) => state.configs.config);
  const { brandsOpen } = useSelector((state: RootState) => state.sideNavigation || false);

  const cookieDomain = useSelector(
    (state: RootState) => state.configs.siteSelect?.cookieDomain || null,
  );

  const currentSite = brands.find((brand) => {
    if (brand.currentSite) {
      return true;
    }

    if (brand.groupSites) {
      return brand.groupSites.find((site) => site.currentSite);
    }

    return false;
  });
  const { primary, dual } = useSelector((state: RootState) => state.logo);

  const hideSideNavigation = (): AnyAction => dispatch(sideNavigationActions.setVisible(false));
  const onSiteSelect = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    skinCode: string,
    isActive: boolean,
  ) => {
    if (isActive) {
      event.preventDefault();
      dispatch(sideNavigationActions.setBrandsOpen(false));
      dispatch(sideNavigationActions.setVisible(false));
      hideSideNavigation();
    } else {
      siteSelect(skinCode, cookieDomain!);
    }
  };

  const shouldShowSiteSelect = brands.length > 1;

  const shouldShowCurrentSite = !config?.siteSelection?.enabledPlatforms
    || config?.siteSelection?.enabledPlatforms?.includes(RSIUtils.detector.detailedDeviceType);

  const getBrandText = useCallback((siteSelectItem: BaseSiteSelectItem) => (
    <>
      <Translate id={`SITE_SELECT_BTN_${siteSelectItem.stateCode}`} />

      {siteSelectItem.brandName && siteSelectItem.brandName.trim() && (
        <BrandItemBrand
          active={siteSelectItem.currentSite}
        >
          {`❘ ${siteSelectItem.brandName.trim()}`}
        </BrandItemBrand>
      )}
    </>
  ), []);

  const isSiteSelectItemGuard = (
    siteSelectItem: BaseSiteSelectItem,
  ): siteSelectItem is SiteSelectItem => (
    siteSelectItem as SiteSelectItem
  ).groupSites !== undefined;

  const getBrandLink = (siteSelectItem: SiteSelectItem | GroupSiteItem) => (
    <SideNavigationItemContainer noImage>
      <BrandItemLink
        indentLevel={!isSiteSelectItemGuard(siteSelectItem) ? siteSelectItem.indentLevel : 0}
        onClick={
          (event) => onSiteSelect(event, siteSelectItem.skinCode, siteSelectItem.currentSite)
        }
        key={siteSelectItem.skinCode}
        active={siteSelectItem.currentSite}
        href={`${siteSelectItem.siteUrl}?setSite=${siteSelectItem.skinCode}`}
        data-testid={`type-brand-link-${siteSelectItem.skinCode}`}
      >
        {getBrandText(siteSelectItem)}
      </BrandItemLink>
    </SideNavigationItemContainer>
  );

  return (
    <>
      <BrandHeader>
        <CloseIcon>
          <Icon
            src="cms/navigationAssets/cross.svg"
            height={21}
            width={21}
            iconClick={hideSideNavigation}
            data-testid="sidenav-close-button"
          />
        </CloseIcon>

        <CurrentBrand
          notClickable={!shouldShowSiteSelect}
          onClick={
            () => shouldShowSiteSelect && dispatch(sideNavigationActions.setBrandsOpen(!brandsOpen))
          }
          data-testid="brand-navigation"
          data-test-type={shouldShowSiteSelect ? 'brand-navigation-button' : 'brand-name'}
        >
          <CurrentBrandIcon>
            <Icon
              src={primary}
              maxHeight={45}
              maxWidth={dual ? 65 : 75}
              xsScreenMaxWidth={dual ? 55 : null}
            />
            {dual && (
              <>
                <DualBrandSeparator />
                <Icon
                  src={dual}
                  maxHeight={35}
                  maxWidth={65}
                  xsScreenMaxWidth={55}
                  height="100%"
                  width="100%"
                />
              </>
            )}
          </CurrentBrandIcon>

          {currentSite && shouldShowCurrentSite && (
            <DropdownLabel open={brandsOpen} noDropdownIcon={!shouldShowSiteSelect}>
              <SideNavigationLabel dualBrandingEnabled={!!dual}>
                <Translate id={`SITE_SELECT_BTN_${currentSite.stateCode}`} />
              </SideNavigationLabel>
            </DropdownLabel>
          )}
        </CurrentBrand>
      </BrandHeader>

      {shouldShowSiteSelect && (
        <BrandSelect
          open={brandsOpen}
          data-testid="brands-list"
        >
          {brands.map((brand, index) => (
            <span key={brand.skinCode}>
              {!!index && (<Separator />)}
              {!!brand.groupSites.length && (
                <BrandItemGroup
                  data-testid={`type-group-${brand.stateCode}`}
                >
                  <SideNavigationItemContainer noImage>
                    <BrandItemButton
                      key={brand.skinCode}
                      group
                      data-testid="type-group-title"
                    >
                      {getBrandText(brand)}
                    </BrandItemButton>
                  </SideNavigationItemContainer>

                  {brand.groupSites.map((site) => (
                    getBrandLink(site)
                  ))}
                </BrandItemGroup>
              )}

              {!brand.groupSites.length && (
                getBrandLink(brand)
              )}
            </span>
          ))}
        </BrandSelect>
      )}
    </>
  );
};

export default BrandNavigation;
