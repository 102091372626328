/* eslint-disable import/prefer-default-export */
import { AnyAction } from 'redux';
import { applicationActionTypes } from '../actionTypes';
import { ScreenWidthType } from '../../interfaces';

export const setMobilePortrait = (payload: boolean): AnyAction => ({
  type: applicationActionTypes.SET_MOBILE_PORTRAIT,
  payload,
});

export const setOrientationLandscape = (isLandscape: boolean): AnyAction => ({
  type: applicationActionTypes.SET_LANDSCAPE,
  isLandscape,
});

export const setScreenWidthType = (payload: ScreenWidthType): AnyAction => ({
  type: applicationActionTypes.SET_SCREEN_WIDTH_TYPE,
  payload,
});

export const setPage = (payload: string): AnyAction => ({
  type: applicationActionTypes.SET_PAGE,
  payload,
});

export const setViewType = (payload: string): AnyAction => ({
  type: applicationActionTypes.SET_VIEW_TYPE,
  payload,
});

export const setSportsLocation = (payload: string): AnyAction => {
  sessionStorage.setItem('sportsLocation', payload);

  return {
    type: applicationActionTypes.SET_SPORTS_LOCATION,
    payload,
  };
};

export const setRsiApiStatus = (payload: boolean): AnyAction => ({
  type: applicationActionTypes.SET_RSIAPI_STATUS,
  payload,
});

export const setViewArgsStatus = (payload: boolean): AnyAction => ({
  type: applicationActionTypes.SET_VIEWARGS_STATUS,
  payload,
});

export const setSupportChatOpenStatus = (payload: boolean): AnyAction => ({
  type: applicationActionTypes.SET_SUPPORT_CHAT_OPEN_STATUS,
  payload,
});

export const setPlayerChatOpenStatus = (payload: boolean): AnyAction => ({
  type: applicationActionTypes.SET_PLAYER_CHAT_OPEN_STATUS,
  payload,
});

export const setGamingMode = (isGamingMode: boolean): AnyAction => ({
  type: applicationActionTypes.SET_GAMING_MODE,
  payload: isGamingMode,
});

export const setShowGameTopBar = (showGameTopBar: boolean): AnyAction => ({
  type: applicationActionTypes.SET_SHOW_GAME_TOP_BAR,
  payload: showGameTopBar,
});
