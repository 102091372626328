import styled, { css } from 'styled-components';

interface BadgedProps {
  badge?: number | string | null;
  badgeImage?: string | null;
}

const BadgedText = styled.span<BadgedProps>`
  ${(props) => props.badge && css`
    position: relative;

    &:before {
      content: '${props.badge}';
      position: absolute;
      left: 100%;
      top: 0;
      display: flex;
      min-width: 14px;
      height: 14px;
      width: 14px;
      padding: 2px;
      transform: translateY(-50%);
      ${!props.badgeImage && css`
        border-radius: 50%;
        background-color: ${props.theme.plain.background.badge};
      `};
      ${props.badgeImage && css`
        height: 20px;
        width: 20px;
        background-image: url("${props.badgeImage}");
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: center center;
      `};
      font-size: 9px;
      font-weight: 700;
      color: ${props.theme.plain.foreground.base};
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  `};
`;

export default BadgedText;
