import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import CompactHeader from './Desktop/CompactHeader';
import { CustomTheme } from '../../interfaces';

interface GameTopNavigationProps {
  showDesktopHeader: boolean;
  theme: CustomTheme;
}

const topBarWithDesktopHeaderHeight = '70px';
const topBarWithoutDesktopHeaderHeight = '60px';
const skeletonColor = '#EFF1F4';

const GameTopBarHeader = styled.header.attrs(
  (props: { showDesktopHeader: boolean }) => props,
)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  ${(props) => !props.showDesktopHeader
    && css`
      height: ${topBarWithoutDesktopHeaderHeight};
    `};
  ${(props) => props.showDesktopHeader
    && css`
      height: ${topBarWithDesktopHeaderHeight};
      top: 35px;
    `};
`;

const TopNavBarSkeleton = styled.div<GameTopNavigationProps>`
  width: 100%;
  ${(props) => !props.showDesktopHeader
    && css`
      height: ${topBarWithoutDesktopHeaderHeight};
    `};
  ${(props) => props.showDesktopHeader
    && css`
      height: ${topBarWithDesktopHeaderHeight};
    `};
  background-color: ${({ theme }) => theme?.themed?.background?.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  @media only screen and {
    height: 70px;
    padding-left: 18px;
    padding-right: 37px;
  }
`;

const ButtonSkeleton = styled.div`
  width: 44px;
  height: 44px;
  opacity: 0.2;
  background-color: ${skeletonColor};
  border-radius: 50%;

  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const LogoSkeleton = styled.div`
  height: 48px;
  width: 130px;
  opacity: 0.2;
  background-color: ${skeletonColor};
  border-radius: 4px;
`;

// TopNavBarSkeleton gets removed in mfe-game-top-navbar code when it's loaded

function GameTopNavigation({
  showDesktopHeader,
  theme,
}: GameTopNavigationProps) {
  const UMDLoader = RSIClient?.UMDLoader;
  return (
    <>
      <GameTopBarHeader showDesktopHeader={showDesktopHeader}>
        <TopNavBarSkeleton
          id="mfe-game-top-navbar-loader"
          showDesktopHeader={showDesktopHeader}
        >
          <LogoSkeleton />
          <ButtonsWrapper>
            <ButtonSkeleton />
            <ButtonSkeleton />
            <ButtonSkeleton />
            <ButtonSkeleton />
          </ButtonsWrapper>
        </TopNavBarSkeleton>
        <UMDLoader
          name="mfe-game-top-navbar"
          props={{ backgroundColor: theme?.themed?.background?.primary }}
        />
      </GameTopBarHeader>
      {showDesktopHeader && <CompactHeader />}
    </>
  );
}
export default withTheme(GameTopNavigation);
