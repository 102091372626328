import { activeImageMap, imageMap } from '../constants';

/* eslint-disable max-len, arrow-body-style, import/prefer-default-export */

export const isValidImage = (imageName: string): imageName is keyof typeof imageMap => {
  return Object.prototype.hasOwnProperty.call(imageMap, imageName);
};

export const isValidActiveImage = (imageName: string): imageName is keyof typeof activeImageMap => {
  return Object.prototype.hasOwnProperty.call(activeImageMap, imageName);
};
