import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BingoInvite, Invitation, RootState, TournamentInvite,
} from '../interfaces';
import { findConfigItem } from '../utils';
import { configActions, invitesActions } from '../store/actions';
import { ItemTypes } from '../interfaces/topNavigation';

export default (): void => {
  const dispatch = useDispatch();
  const [invitation, setInvitation] = useState<Invitation | null>(null);
  const [timeoutRef, setTimeoutRef] = useState<any>(null);
  const rsiApiLoaded = useSelector((state: RootState) => state.application.rsiApiLoaded);
  const topNavigation = useSelector((state: RootState) => state.configs.topNavigation);
  const tournamentsConfig = useSelector((state: RootState) => state.configs.tournamentsConfig);
  // set up listener
  const initializeBingo = (): void => {
    rsiApi.on(rsiApi.getEvent('SWE_INVITE'), (invite: BingoInvite) => {
      const time = invite.startDate;
      const type = ItemTypes.Bingo;
      setInvitation({
        time,
        type,
      });
    });
  };

  const initializeTournaments = (): void => {
    const config = findConfigItem(
      topNavigation?.items || [],
      ItemTypes.Tournament,
    );

    if (config) {
      dispatch(configActions.getTournamentsConfig());
    }
  };

  const updateTime = (lastTime: number, type: ItemTypes, clock: any): void => {
    const newTime = lastTime - 1000;

    let formattedTime: string | null = null;
    if (newTime > 0) {
      const secondsTime = Math.round(newTime / 1000);
      formattedTime = clock.formatTime(secondsTime, true);
    }
    dispatch(invitesActions.setInvite({
      time: formattedTime,
      type,
      data: (newTime > 0 ? invitation?.data : undefined),
    }));

    let newTimeout = null;
    if (newTime > 0) {
      newTimeout = setTimeout(() => {
        updateTime(newTime, type, clock);
      }, 1000);
    }
    setTimeoutRef(newTimeout);
  };

  useEffect(() => {
    if (!rsiApiLoaded || !topNavigation) {
      return;
    }

    initializeBingo();
    initializeTournaments();
    // eslint-disable-next-line
  }, [rsiApiLoaded, topNavigation]);

  useEffect(() => {
    if (!invitation) {
      return;
    }

    if (timeoutRef) {
      clearTimeout(timeoutRef);
      setTimeoutRef(null);
    }

    const clock = rsiApi.getModule('components/Clock');
    const time = clock.getCageTimeDiff(invitation.time, true);
    updateTime(time, invitation.type, clock);

    // eslint-disable-next-line
  }, [invitation]);

  const calculateRemainingTimeForTournamentTimer = (unregistrationEndTime: string): number => {
    const clock = rsiApi.getModule('components/Clock');
    const notificationTimerInSeconds = 120;
    const cageTimeDiff = clock.getCageTimeDiff(unregistrationEndTime, true);
    return cageTimeDiff - 1000 * notificationTimerInSeconds;
  };
  const startTimerOnTopNavigation = (invite: TournamentInvite): void => {
    setInvitation({
      time: invite.unregistrationEndTime,
      type: ItemTypes.Tournament,
      data: invite.eventId,
    });
  };

  useEffect(() => {
    if (!tournamentsConfig) {
      return;
    }
    rsiApi.on(rsiApi.getEvent('TOURNAMENT_UNREG_END'), (invite: TournamentInvite) => {
      const match = tournamentsConfig.tournamentItems.find(
        ({ code }) => code === invite.eventCode,
      );

      if (!match) {
        return;
      }
      setTimeout(
        () => { startTimerOnTopNavigation(invite); },
        calculateRemainingTimeForTournamentTimer(invite.unregistrationEndTime),
      );
    });
  }, [tournamentsConfig]);
};
