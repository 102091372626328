import { AnyAction } from 'redux';
import { invitesActionTypes } from '../actionTypes';
import { InviteState } from '../../interfaces';

const initialState: InviteState = {
  timeLeft: null,
  hasInvite: false,
  type: null,
  data: null,
};

export default (state = initialState, action: AnyAction): InviteState => {
  switch (action.type) {
    case invitesActionTypes.SET_INVITE:
      return {
        ...state,
        timeLeft: action.invite.time,
        hasInvite: !!action.invite.time,
        type: action.invite.type,
        data: action.invite.data || null,
      };
    default:
      return state;
  }
};
