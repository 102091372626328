import { AnyAction } from 'redux';
import { logoActionTypes } from '../actionTypes';
import { LogoState } from '../../interfaces';

const initialState: LogoState = {
  primary: 'cms/navigationAssets/logo.svg',
  dual: null,
};

export default (state = initialState, action: AnyAction): LogoState => {
  switch (action.type) {
    case logoActionTypes.SET_LOGO_PATHS:
      return {
        ...state,
        primary: action.logoPaths.primary,
        dual: action.logoPaths.dual,
      };
    default:
      return state;
  }
};
