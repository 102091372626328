/* eslint-disable import/prefer-default-export */
import { AnyAction } from 'redux';
import { playerActionTypes } from '../actionTypes';
import { BalanceUpdate, Loyalty, OldBalance } from '../../interfaces/player';

export const setPlayer = (player: any): AnyAction => ({
  type: playerActionTypes.SET_PLAYER,
  player,
});

export const setPlayerProfile = (profile: any): AnyAction => ({
  type: playerActionTypes.SET_PLAYER_PROFILE,
  profile,
});

export const setPlayerAvatar = (avatar: string): AnyAction => ({
  type: playerActionTypes.SET_PLAYER_AVATAR,
  avatar,
});

export const setPlayerLoyaltyBar = (loyalty: Loyalty | null): AnyAction => ({
  type: playerActionTypes.SET_PLAYER_LOYALTY_BAR,
  loyalty,
});

export const setPlayerBonusBar = (bonusBar: number | null): AnyAction => ({
  type: playerActionTypes.SET_PLAYER_BONUS_BAR,
  bonusBar,
});

export const setPlayerBalance = (balance: OldBalance): AnyAction => ({
  type: playerActionTypes.SET_PLAYER_BALANCE,
  balance,
});

export const setPlayerNewBalance = (newBalance: BalanceUpdate): AnyAction => ({
  type: playerActionTypes.SET_PLAYER_NEW_BALANCE,
  newBalance,
});

export const setPlayerCustomTokens = (tokenCount: number | null): AnyAction => ({
  type: playerActionTypes.SET_PLAYER_CUSTOM_TOKENS,
  tokenCount,
});

export const setPlayerLoyaltyTokens = (tokenCount: number | null): AnyAction => ({
  type: playerActionTypes.SET_PLAYER_LOYALTY_TOKENS,
  tokenCount,
});
