import {
  useBalance,
  useBonusBar,
  useInvitations,
  useLoyaltyLevels,
  usePlayerProfile,
  useViewArgs,
} from '../../hooks';

/**
 * Most of the custom hooks are functional that just dispatch some events and some use useSelect
 * redux hook (easy to use). That useSelect triggers re-rendering. All these hooks are gathered
 * here with 0 visual component to avoid unnecessary rendering.
 */
function TopNavigationHooksContainer(): null {
  useViewArgs();
  useLoyaltyLevels();
  useBonusBar();
  usePlayerProfile();
  useInvitations();
  useBalance();

  return null;
}

export default TopNavigationHooksContainer;
