import styled from 'styled-components';

export const HeaderLeftContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const HeaderRightContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const HeaderContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1620px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.themed.background.primary};
  transition: background-color 200ms ease;

  & button + button {
    margin-left: 10px;
  }
`;

export default Header;
