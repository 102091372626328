import styled, { css } from 'styled-components';

interface BackdropProps {
  visible?: boolean;
  level?: number;
}

const Backdrop = styled.div<BackdropProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: .7;

  ${(props) => props.level && css`
    z-index: ${props.level};
  `}

  ${(props) => props.visible && css`
    background-color: ${props.theme.themed.background.backdrop};
  `}
`;

export default Backdrop;
