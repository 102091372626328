import React from 'react';
import styled, { css } from 'styled-components';
import DropdownLabel from './DropdownLabel';
import Translate from '../Translate';

interface LabeledItemProps {
  label: string;
  open: boolean;
  highlightedLabel?: boolean;
  noDropdownIcon?: boolean;
  direction?: 'column' | 'row';
  compact?: boolean;
  onClick?: () => any;
  dataTarget?: string;
  className?: string;
  noTranslate?: boolean;
  children?: React.ReactNode;
}

const Container = styled.div<Partial<LabeledItemProps>>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  ${(props) => !props.direction && props.compact && css`
    flex-direction: column;
  `}
  ${(props) => !props.direction && !props.compact && css`
    flex-direction: row;
  `}

  /* force flex direction */
  ${(props) => props.direction && css`
    flex-direction: ${props.direction};
  `}

  ${(props) => props.onClick && css`
    cursor: pointer;
  `}

  @media (min-width: 300px) {
    margin-left: -1px;
  }

  & + & {
    margin-left: 8px;

    @media (min-width: 420px) {
      margin-left: 15px;
    }
  }
`;

const Label = styled.span<Partial<LabeledItemProps>>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) => !props.compact && css`
    font-size: 12px;
  `}
  ${(props) => props.compact && css`
    font-size: 8px;
  `}
  ${(props) => !props.highlightedLabel && css`
    color: ${props.theme.themed.foreground.base};
  `}
  ${(props) => props.highlightedLabel && css`
    color: ${props.theme.themed.foreground.linkActive};

    ${props.compact && css`
      font-size: 10px;
    `}
  `}
  ${(props) => !props.noDropdownIcon && css`
    padding-left: .5em;
  `}
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
`;

const LabeledItem: React.FC<LabeledItemProps> = (
  {
    children,
    compact,
    dataTarget,
    direction,
    label,
    onClick,
    open,
    highlightedLabel,
    noDropdownIcon,
    className,
    noTranslate,
  },
) => (
  <Container
    onClick={onClick}
    data-target={dataTarget}
    compact={compact}
    direction={direction}
    className={className}
  >
    {children}

    <Label compact={compact} highlightedLabel={highlightedLabel} noDropdownIcon={noDropdownIcon}>
      <DropdownLabel open={open} noDropdownIcon={noDropdownIcon}>
        {noTranslate && label}
        {!noTranslate && (
          <Translate id={label} />
        )}
      </DropdownLabel>
    </Label>
  </Container>
);

LabeledItem.defaultProps = {
  highlightedLabel: undefined,
  noDropdownIcon: undefined,
  direction: undefined,
  compact: undefined,
  onClick: undefined,
  dataTarget: undefined,
  className: undefined,
  noTranslate: undefined,
  children: undefined,
};

export default LabeledItem;
