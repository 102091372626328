import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import { defaultTheme } from './constants';
import { RootState } from './interfaces';
import GlobalHooksContainer from './components/GlobalHooks';
import BottomNavigationContainer from './components/BottomNavigationContainer';
import SideNavigationContainer from './components/SideNavigationContainer';
import TopNavigationContainer from './components/TopNavigationContainer';
import WebGlobalHooksContainer from './components/WebGlobalHooks';
import GlobalStyle from './components/rsi/GlobalStyle';
import FrameStyleContainer from './components/FrameStyleContainer';
import eventBus from './services/eventBus';
import useTranslations from './hooks/useTranslations';
import useLocale from './hooks/useLocale';

function App() {
  const theme = useSelector((state: RootState) => state.configs.theme);
  const isAndroidApp = RSIUtils.detector.isAndroid && RSIUtils.detector.isApp;
  const [bottomNavigationVisible, setBottomNavigationVisible] = useState<boolean>(true);
  const locale = useLocale();
  const messages = useTranslations('translations');

  useEffect(() => {
    eventBus.subscribe(eventBus.eventTypes.RSI_BOTTOM_NAVIGATION_TOGGLE, (visible: boolean) => {
      setBottomNavigationVisible(visible);
    });
  }, []);

  /**
   * Touchmove needed for SPORT-646.
   * Kambi adds an event listener to window that doesn't let the user scroll
   * in certain occasions when the betslip is open.
   *
   * Resize needed for SPORT-707.
   * An event listener is added to window that overrides the calculation for
   * max-height property for .mod-KambiBC-betslip.
   *
   * This is a wrapper for adding event listeners so that we can catch the ones
   * that break something and just not add them.
   */
  const sportsKambiBetslipHack = useCallback(() => {
    const current = window.addEventListener;
    window.addEventListener = (
      eventType: string,
      listener: EventListenerOrEventListenerObject,
    ): void => {
      if (
        (eventType !== 'touchmove'
          || !listener.toString()
            .includes('this.state.maximized'))
        && (eventType !== 'resize' || !listener.toString()
          .includes('if(s=arguments,l=this,p=e,n)'))
      ) {
        // @ts-ignore
        current.apply(this, [eventType, listener]);
      }
    };
    return () => {
      window.addEventListener = current;
    };
  }, []);

  useEffect(() => {
    const cleanup = sportsKambiBetslipHack();
    return () => {
      cleanup();
    };
  }, [sportsKambiBetslipHack]);

  if (RSIUtils.detector.isNewIOSApp) {
    return null;
  }

  if (!locale || !messages) {
    return null;
  }

  return (
    <IntlProvider messages={messages} locale={locale}>
      <ThemeProvider theme={theme || defaultTheme}>
        <GlobalHooksContainer />
        <FrameStyleContainer hasTopNav={!isAndroidApp} />
        <GlobalStyle />

        {isAndroidApp && bottomNavigationVisible && (
          <BottomNavigationContainer />
        )}

        {!isAndroidApp && (
          <>
            <WebGlobalHooksContainer />
            <TopNavigationContainer />
            {bottomNavigationVisible && (
              <BottomNavigationContainer />
            )}
            <SideNavigationContainer />
          </>
        )}
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
