import {
  Alignment, Fit, Layout, useRive, useStateMachineInput,
} from '@rive-app/react-canvas';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const BadgeContainer = styled.div`
  position: absolute;
  height: 40px;
  width: 40px;
  bottom: 20px;
  left: -4px;
  .chat-badge-number {
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: center;
    font-size: 10px;
  }
`;

const notificationInput = 'New notification';
const numberInput = 'Number 1';
const artboard = 'Top navigation Chat badge';
const stateMachineName = 'State Machine 1';
const animationFileSrc = './cms/chat/chat_notification_icon.riv';
const NOTIFICATION_STATE = {
  CLEAR: 2,
  IDLE: 1,
};

let sub: { unsubscribe: Function } = { unsubscribe: () => null };

const layout = new Layout({
  alignment: Alignment.Center,
  fit: Fit.Contain,
});

export default function ChatIconBadge() {
  const [unreadCount, setUnreadCount] = useState(0);
  const { rive, RiveComponent } = useRive({
    src: animationFileSrc,
    layout,
    autoplay: true,
    artboard,
    stateMachines: stateMachineName,
  }, {
    fitCanvasToArtboardHeight: true,
  });

  const input = useStateMachineInput(rive, stateMachineName, notificationInput);
  const notificationState = useStateMachineInput(
    rive,
    stateMachineName,
    numberInput,
    NOTIFICATION_STATE.CLEAR,
  );

  useEffect(() => {
    sub.unsubscribe();
    sub = RSIEventBus.subscribe(
      RSIEventBus.eventTypes.PLAYER_CHAT_MESSAGE_COUNT,
      ({ unread }: { unread: number }) => {
        if (input && notificationState) {
          if (unread > 0) {
            notificationState.value = NOTIFICATION_STATE.IDLE;
            input.fire();
          }
          if (unread === 0) {
            notificationState.value = NOTIFICATION_STATE.CLEAR;
          }
          setUnreadCount(unread);
        }
      },
    );
    return () => {
      sub.unsubscribe();
    };
  }, [input, notificationState]);

  return (
    <BadgeContainer>
      <div className="chat-badge-number">{unreadCount || ''}</div>
      <RiveComponent className="chat-badge-dot" />
    </BadgeContainer>
  );
}
