import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import Icon from '../../SideNavigation/Icon';
import { filterConfigItemsAllowed, isValidActiveImage, isValidImage } from '../../../utils';
import { actionHandlers } from '../../../store/actions';
import { RootState, RsiApiEventTypes } from '../../../interfaces';
import { activeImageMap, imageMap } from '../../../constants';
import { ImageItem, ItemTypes, ProductItem } from '../../../interfaces/topNavigation';
import { logger } from '../../../services';
import Translate from '../../Translate';

// stylelint-disable order/order
interface ProductContainerProps {
  active?: boolean;
  isMobileIos?: boolean;
}

export interface IconContainerProps {
  activeIcon?: boolean;
  hideIcon?: boolean;
}

export const IconContainer = styled(Icon) <IconContainerProps>`
  margin-right: ${(props) => (props.className?.includes('label-hidden') ? '0' : '5px')};

  ${(props) => props.activeIcon && css`
    position: absolute;
  `};

  ${(props) => props.hideIcon && css`
    visibility: hidden;
  `};
`;

const ProductContainer = styled.a<ProductContainerProps>`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  font-size: 12px;
  font-weight: 700;
  color: ${(props) => props.theme.themed.foreground.linkColor};
  text-transform: uppercase;
  transition: color 200ms ease;

  ${IconContainer} {
    opacity: 1;
    transition: opacity 180ms ease;

    ${(props) => !props.active && css`
      &.active-icon-container {
        opacity: 0;
      }
    `};
  }

  &:focus,
  &:hover {
    text-decoration: none !important;
    color: ${(props) => props.theme.themed.foreground.linkActive} !important;
  }

  ${(props) => !props.isMobileIos && css`
    &:focus,
    &:hover {
      ${IconContainer} {
        opacity: 0;
      }

      .active-icon-container {
        opacity: 1 !important;
      }
    }
  `}

  ${(props) => props.active && css`
    color: ${props.theme.themed.foreground.linkActive};

    &:focus,
    &:hover {
      color: ${props.theme.themed.foreground.linkActive} !important;
    }

    /* left and right should match the parent side paddings */
    &:after {
      content: "";
      position: absolute;
      left: 10px;
      right: 10px;
      bottom: 0;
      height: 3px;
      pointer-events: none;
      border-radius: inherit;
      background-color: ${props.theme.themed.foreground.linkActive};
    }
  `};
`;

const ProductNavigation: React.FC = () => {
  const topNavigation = useSelector((state: RootState) => state.configs.topNavigation);
  const {
    page,
    isGamingMode,
    isLandscape,
    rsiApiLoaded,
  } = useSelector((state: RootState) => state.application);
  const loggedIn = useSelector((state: RootState) => state.player.loggedIn);
  const navigationItems = filterConfigItemsAllowed(
    topNavigation?.items || [],
    loggedIn,
    isGamingMode,
    undefined,
    isLandscape,
    // eslint-disable-next-line arrow-body-style
  ).filter((item): item is ImageItem | ProductItem => (
    item.type === ItemTypes.Casino
    || item.type === ItemTypes.LiveCasino
    || item.type === ItemTypes.Sportsbook
    || item.type === ItemTypes.Poker
    || item.type === ItemTypes.CasinoQuickLaunch
  ));

  /*
   * PRM-3021 This exists due to IOS having issue with links that have
   * visibility change within them. So since we need two icons to
   * animate their visibility, we separate the link element from the actual item
   * https://css-tricks.com/annoying-mobile-double-tap-link-issue/
   */
  const isMobileIos = RSIUtils.detector.isIOS;

  const isLinkItem = (item: ImageItem | ProductItem): item is ProductItem => !!(item as any).link;

  const isActive = (item: ImageItem | ProductItem): boolean => {
    if (!page) {
      return false;
    }

    if (item.type === ItemTypes.CasinoQuickLaunch) {
      return isGamingMode;
    }

    if (isLinkItem(item)) {
      return item.link.includes(page);
    }

    return false;
  };

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    item: ImageItem | ProductItem,
  ): void => {
    const analyticsEvent = {
      category: 'Top navigation',
      action: 'click',
      label: `${item.type} ${isLinkItem(item) ? item.link : item.translationKey}`,
    };

    RSIAnalytics.event(analyticsEvent);
    logger.info(`Top navigation ${item.type} click event`, analyticsEvent);

    if (isLinkItem(item)) {
      return;
    }

    event.preventDefault();

    actionHandlers.handleRsiApiTrigger({
      event: RsiApiEventTypes.ToggleCasinoQuickLaunch,
    }, rsiApiLoaded);
  };

  return (
    <>
      {navigationItems.map((item) => (
        <ProductContainer
          key={item.translationKey}
          href={isLinkItem(item) ? item.link : ''}
          active={isActive(item)}
          onClick={(event) => handleClick(event, item)}
          className={item.type === ItemTypes.CasinoQuickLaunch ? 'deny-game-list-close' : ''}
          isMobileIos={isMobileIos}
          data-testid={`product-navigation-${item.translationKey}`}
          data-test-type={item.type}
        >
          {item.image && isValidImage(item.image) && isValidActiveImage(item.image) && (
            <>
              <IconContainer
                hideIcon={isActive(item)}
                src={`cms/navigationAssets/${imageMap[item.image]}`}
                alt={item.image}
                maxHeight={25}
                maxWidth={25}
                imageDataTestId="product-navigation-image"
              />

              <IconContainer
                className="active-icon-container"
                activeIcon
                src={`cms/navigationAssets/${activeImageMap[item.image]}`}
                alt={item.image}
                maxHeight={25}
                maxWidth={25}
                imageDataTestId="product-navigation-active-image"
              />
            </>
          )}
          {/*
           * We allow products to be just images
           */}
          <Translate id={item.translationKey} />
        </ProductContainer>
      ))}
    </>
  );
};

export default ProductNavigation;
