import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  BaseSiteSelectItem, GroupSiteItem, RootState, SiteSelectItem,
} from '../../../interfaces';
import { siteSelect } from '../../../utils';
import Backdrop from '../../rsi/Backdrop';
import DropdownLabel from '../../Wrappers/DropdownLabel';

import {
  BrandContainer,
  BrandHeader,
  BrandItemBrand,
  BrandItemButton,
  BrandItemGroup,
  BrandItemLink,
  BrandSelect,
  CurrentBrand,
} from './Brand';
import Translate from '../../Translate';

const BrandNavigation: React.FC = () => {
  const [brandsOpen, setBrandsOpen] = useState(false);

  const brands = useSelector((state: RootState) => state.configs.siteSelect?.items || []);
  const cookieDomain = useSelector(
    (state: RootState) => state.configs.siteSelect?.cookieDomain || null,
  );

  const currentSite = brands.find((brand) => {
    if (brand.currentSite) {
      return true;
    }

    if (brand.groupSites) {
      return brand.groupSites.find((site) => site.currentSite);
    }

    return false;
  });

  const onSiteSelect = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    skinCode: string,
    isActive: boolean,
  ) => {
    if (isActive) {
      event.preventDefault();
      setBrandsOpen(false);
    } else {
      siteSelect(skinCode, cookieDomain!);
    }
  };

  const shouldShowSiteSelect = brands.length > 1;

  const getBrandText = useCallback((siteSelectItem: BaseSiteSelectItem) => (
    <>
      <Translate id={`SITE_SELECT_BTN_${siteSelectItem.stateCode}`} />

      {siteSelectItem.brandName && siteSelectItem.brandName.trim() && (
        <BrandItemBrand
          active={siteSelectItem.currentSite}
        >
          {`❘ ${siteSelectItem.brandName.trim()}`}
        </BrandItemBrand>
      )}
    </>
  ), []);

  const isSiteSelectItemGuard = (
    siteSelectItem: BaseSiteSelectItem,
  ): siteSelectItem is SiteSelectItem => (
    siteSelectItem as SiteSelectItem
  ).groupSites !== undefined;

  const getBrandLink = (siteSelectItem: SiteSelectItem | GroupSiteItem) => (
    <BrandItemLink
      indentLevel={!isSiteSelectItemGuard(siteSelectItem) ? siteSelectItem.indentLevel : 0}
      onClick={(event) => onSiteSelect(
        event,
        siteSelectItem.skinCode,
        siteSelectItem.currentSite,
      )}
      key={siteSelectItem.skinCode}
      active={siteSelectItem.currentSite}
      href={`${siteSelectItem.siteUrl}?setSite=${siteSelectItem.skinCode}`}
      group={false}
      data-testid={`type-brand-link-${siteSelectItem.skinCode}`}
    >
      {getBrandText(
        siteSelectItem,
      )}
    </BrandItemLink>
  );

  if (brands.length === 0) {
    return null;
  }

  return (
    <BrandContainer>
      <BrandHeader
        noCursor={!shouldShowSiteSelect}
        onClick={() => setBrandsOpen(shouldShowSiteSelect && !brandsOpen)}
        data-testid="brand-navigation"
        data-test-type={shouldShowSiteSelect ? 'brand-navigation-button' : 'brand-name'}
      >
        <CurrentBrand open={brandsOpen}>
          {currentSite && (
            <DropdownLabel
              open={brandsOpen}
              noDropdownIcon={!shouldShowSiteSelect}
              isActive={brandsOpen}
            >
              <Translate id={`SITE_SELECT_BTN_${currentSite.stateCode}`} />
            </DropdownLabel>
          )}
        </CurrentBrand>
      </BrandHeader>

      {shouldShowSiteSelect && brandsOpen && (
        <>
          <Backdrop
            onClick={() => setBrandsOpen(false)}
            data-testid="brand-navigation-backdrop"
          />

          <BrandSelect data-testid="brands-list">
            {brands.map((brand) => {
              if (brand.groupSites.length) {
                return (
                  <BrandItemGroup
                    data-testid={`type-group-${brand.stateCode}`}
                    key={brand.skinCode}
                  >
                    <BrandItemButton
                      active={brand.currentSite}
                      group
                      data-testid="type-group-title"
                    >
                      {getBrandText(
                        brand,
                      )}
                    </BrandItemButton>

                    {brand.groupSites.map((site) => (
                      getBrandLink(site)
                    ))}
                  </BrandItemGroup>
                );
              }

              return getBrandLink(brand);
            })}
          </BrandSelect>
        </>
      )}
    </BrandContainer>
  );
};

export default BrandNavigation;
