import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../interfaces';
import { applicationActions } from '../store/actions';

export default (): void => {
  const dispatch = useDispatch();
  const isMaximized = useSelector((state: RootState) => state.betslip.isMaximized);
  const isMobilePortrait = useSelector((state: RootState) => state.application.isMobilePortrait);
  const rsiApiLoaded = useSelector((state: RootState) => state.application.rsiApiLoaded);

  const appEventListenerCallback = useCallback(({ type }: {type: string}) => {
    switch (type) {
      case 'hidingSupportChatWindow':
        dispatch(applicationActions.setSupportChatOpenStatus(false));
        break;
      case 'showingSupportChatWindow':
        if (isMaximized && isMobilePortrait && window.RSIKambiController) {
          RSIKambiController.setBetslipMaximized(false);
        }
        dispatch(applicationActions.setSupportChatOpenStatus(true));
        break;
      case 'hidingPlayerChatWindow':
        dispatch(applicationActions.setPlayerChatOpenStatus(false));
        break;
      case 'showingPlayerChatWindow':
        if (isMaximized && isMobilePortrait && window.RSIKambiController) {
          RSIKambiController.setBetslipMaximized(false);
        }
        dispatch(applicationActions.setPlayerChatOpenStatus(true));
        break;
      default:
        break;
    }
  }, [dispatch, isMaximized, isMobilePortrait]);

  useEffect(() => {
    if (!rsiApiLoaded) {
      return () => {};
    }

    rsiApi.on(rsiApi.getEvent('SEND_APP_EVENT'), appEventListenerCallback);

    return () => {
      rsiApi.off(rsiApi.getEvent('SEND_APP_EVENT'), appEventListenerCallback);
    };
  }, [rsiApiLoaded, appEventListenerCallback]);
};
