import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPlayerBonusBar } from '../store/actions/player';
import { RootState } from '../interfaces';
import { logger } from '../services';

export default (): void => {
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);
  const rsiApiLoaded = useSelector((state: RootState) => state.application.rsiApiLoaded);
  const loggedIn = useSelector((state: RootState) => state.player.loggedIn);

  // update bonus bar with bonusHandler
  const updateBonusBar = (): void => {
    if (!rsiApi.hasModule('handlers/BonusHandler')) {
      logger.warn('No bonus handler module available');
      return;
    }
    const bonusHandler = rsiApi.getModule('handlers/BonusHandler');
    const fill: number | null = bonusHandler.getFillBar();
    dispatch(setPlayerBonusBar(fill));
  };

  // set up listener
  const initializeBonusBar = (): void => {
    rsiApi.on(rsiApi.getEvent('BONUS_BAR_UPDATE'), (bonusBar: number | null) => {
      dispatch(setPlayerBonusBar(bonusBar));
    });

    rsiApi.on([
      rsiApi.getEvent('WAGERING_BONUS_CLEARED'),
      rsiApi.getEvent('WAGERING_BONUS_COMPLETE'),
      rsiApi.getEvent('WAGERING_BONUS_VOIDED'),
    ].join(' '), () => {
      dispatch(setPlayerBonusBar(null));
    });

    setInitialized(true);
  };

  useEffect(() => {
    if (!rsiApiLoaded) {
      return;
    }

    initializeBonusBar();
    // eslint-disable-next-line
  }, [rsiApiLoaded]);

  useEffect(() => {
    if (!initialized) {
      return;
    }

    updateBonusBar();
    // eslint-disable-next-line
  }, [initialized, loggedIn]);
};
