export const isClient: boolean = typeof window === 'object';

export { default as applyMixins } from './applyMixins';
export { default as env } from './env';

export * from './auth';
export * from './calculate';
export * from './configFilters';
export * from './events';
export * from './imageValidator';
export * from './siteSelect';
export * from './topNavHeight';
