import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import GameTopNavigation from './TopNavigation/GameTopNavigation';
import { RootState, ScreenWidthType } from '../interfaces';
import GlobalTopNavigationStyle from './rsi/GlobalTopNavigationStyle';
import { configActions } from '../store/actions';
import TopNavigationHooksContainer from './TopNavigation/TopNavigationHooks';
import MobileNavigation from './TopNavigation/MobileNavigation';
import DesktopNavigation from './TopNavigation/DesktopNavigation';

const TopBarContainer = styled.div`
  position: relative;
  z-index: 6;

  .view-type-sports & {
    z-index: 303;
  }

  .modal-opened:not(.blocking-modal-opened) & {
    z-index: 10000;
  }

  .view-type-sports.game-open & {
    z-index: 306;
  }
`;

function TopNavigationContainer() {
  const dispatch = useDispatch();
  const screenWidthType = useSelector((state: RootState) => state.application.screenWidthType);
  const showGameTopBar = useSelector((state: RootState) => state.application.showGameTopBar);
  const showDesktopHeader = screenWidthType === ScreenWidthType.LARGE;

  useEffect(() => {
    dispatch(configActions.getLoyaltyLevelsConfig());
    dispatch(configActions.getSiteSelectConfig());
  }, [dispatch]);

  return (
    <TopBarContainer id="rsi-top-navigation">
      <GlobalTopNavigationStyle screenWidthType={screenWidthType} showGameTopBar={showGameTopBar} />
      <TopNavigationHooksContainer />
      {showGameTopBar && <GameTopNavigation showDesktopHeader={showDesktopHeader} />}
      {!showGameTopBar && (
        <>
          {!showDesktopHeader && <MobileNavigation />}
          {showDesktopHeader && <DesktopNavigation />}
        </>
      )}
    </TopBarContainer>
  );
}

export default TopNavigationContainer;
