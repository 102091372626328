/* eslint-disable import/prefer-default-export */
import { AnyAction } from 'redux';
import { betslipActionTypes } from '../actionTypes';

export const setItemsCount = (payload: number): AnyAction => ({
  type: betslipActionTypes.SET_ITEMS_COUNT,
  payload,
});

export const setIsMaximized = (payload: boolean): AnyAction => ({
  type: betslipActionTypes.SET_IS_MAXIMIZED,
  payload,
});

export const setIsEnabled = (payload: boolean): AnyAction => ({
  type: betslipActionTypes.SET_IS_ENABLED,
  payload,
});
