import React from 'react';
import { useSelector } from 'react-redux';
import GameFrameGlobalStyle from './rsi/GameFrameGlobalStyle';
import { RootState } from '../interfaces';
import WebWidgetGlobalStyle from './rsi/WebWidgetGlobalStyle';

interface FrameStyleProps {
  hasTopNav: boolean;
}
function FrameStyleContainer({ hasTopNav }: FrameStyleProps) {
  const screenWidthType = useSelector((state: RootState) => state.application.screenWidthType);
  const showGameTopBar = useSelector((state: RootState) => state.application.showGameTopBar);
  const isMobilePortrait = useSelector((state: RootState) => state.application.isMobilePortrait);
  const isSupportChatOpen = useSelector((state: RootState) => state.application.isSupportChatOpen);
  const hasBottomNav = !showGameTopBar && isMobilePortrait;

  return (
    <>
      <GameFrameGlobalStyle
        screenWidthType={screenWidthType}
        hasGameTopBar={showGameTopBar}
        hasBottomNav={hasBottomNav}
        hasTopNav={hasTopNav}
      />
      <WebWidgetGlobalStyle
        screenWidthType={screenWidthType}
        hasGameTopBar={showGameTopBar}
        hasBottomNav={hasBottomNav}
        hasTopNav={hasTopNav}
        supportChatVisible={isSupportChatOpen}
      />
    </>
  );
}

export default FrameStyleContainer;
