import React from 'react';
import { useSelector } from 'react-redux';
import { findConfigItem, isConfigItemAllowed } from '../../utils';
import { RootState } from '../../interfaces';
import { ItemTypes } from '../../interfaces/topNavigation';
import { Button } from '../TopNavigation/Button';
import { logger } from '../../services';
import Translate from '../Translate';

const Authentication: React.FC = () => {
  const {
    rsiApiLoaded,
  } = useSelector((state: RootState) => state.application);
  const { loggedIn } = useSelector((state: RootState) => state.player);
  const { topNavigation } = useSelector((state: RootState) => state.configs);

  const loginConfig = findConfigItem(
    topNavigation?.items || [],
    ItemTypes.Login,
  );

  const signupConfig = findConfigItem(
    topNavigation?.items || [],
    ItemTypes.Signup,
  );

  const openLogin = (): void => {
    if (rsiApiLoaded) {
      rsiApi.trigger(rsiApi.getEvent('OPEN_LOGIN_MODAL'));
    } else {
      logger.error('Cannot open login, rsiApi not loaded');
    }
  };

  const navigateSignup = (): void => {
    // PRM-3268: this closes only the login modal in registration page
    if (rsiApiLoaded) {
      rsiApi.trigger(rsiApi.getEvent('CLOSE_LOGIN_MODAL'));
    }

    RSINavigationHandler.performNavigation({
      page: 'signup',
    });
  };

  if (loggedIn) {
    return null;
  }

  return (
    <>
      {isConfigItemAllowed(loginConfig, loggedIn) && (
        <Button type="button" bordered onClick={openLogin}>
          <Translate id={loginConfig!.translationKey} />
        </Button>
      )}

      {isConfigItemAllowed(signupConfig, loggedIn) && (
        <Button type="button" raised color="primary" onClick={navigateSignup}>
          <Translate id={signupConfig!.translationKey} />
        </Button>
      )}
    </>
  );
};

export default Authentication;
