import React from 'react';

import { HeaderContentWrapper } from '../rsi/Header';
import MobileHeader from './Mobile/Header';
import HeaderLeft from './Mobile/HeaderLeft';
import HeaderRight from './Mobile/HeaderRight';

const MobileNavigation: React.FC = () => (
  <MobileHeader>
    <HeaderContentWrapper>
      <HeaderLeft />

      <HeaderRight />
    </HeaderContentWrapper>
  </MobileHeader>
);

export default MobileNavigation;
