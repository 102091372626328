import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ProgressCircle from '../Wrappers/ProgressCircle';
import LabeledItem from '../Wrappers/LabeledItem';
import { findConfigItem, isConfigItemAllowed } from '../../utils';
import { LoyaltyLevelColorsConfig, RootState } from '../../interfaces';
import { ItemTypes } from '../../interfaces/topNavigation';
import { logger } from '../../services';

interface PlayerProfileProps {
  compact?: boolean;
  hideDropdownArrow?: boolean;
}

const PlayerProfile: React.FC<PlayerProfileProps> = ({ compact, hideDropdownArrow }) => {
  const { loggedIn, loyalty, profile } = useSelector((state: RootState) => state.player);
  const { rsiApiLoaded } = useSelector((state: RootState) => state.application);
  const {
    topNavigation,
    loyaltyLevelColors,
    config,
  } = useSelector((state: RootState) => state.configs);

  const [isOpen, setIsOpen] = useState(false);

  const accountConfig = findConfigItem(
    topNavigation?.items || [],
    ItemTypes.MyAccount,
  );

  const toggleAccountMenu = useCallback((): void => {
    const event = {
      category: 'Top navigation',
      action: 'click',
      label: `${ItemTypes.MyAccount} ${isOpen ? 'close' : 'open'}`,
    };

    RSIAnalytics.event(event);
    logger.info(`Top navigation ${ItemTypes.MyAccount} click event`, event);

    rsiApi.trigger(rsiApi.getEvent(isOpen ? 'CLOSE_ACCOUNT_MENU' : 'OPEN_ACCOUNT_MENU'));
  }, [isOpen]);

  useEffect(() => {
    if (!rsiApiLoaded) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return (): void => {};
    }

    const openMenuCallback = (): void => {
      setIsOpen(true);
    };

    const closeMenuCallback = (): void => {
      setIsOpen(false);
    };

    rsiApi.on(rsiApi.getEvent('OPEN_ACCOUNT_MENU'), openMenuCallback);
    rsiApi.on(rsiApi.getEvent('CLOSE_ACCOUNT_MENU'), closeMenuCallback);

    return (): void => {
      rsiApi.off(rsiApi.getEvent('OPEN_ACCOUNT_MENU'), openMenuCallback);
      rsiApi.off(rsiApi.getEvent('CLOSE_ACCOUNT_MENU'), closeMenuCallback);
    };
  }, [rsiApiLoaded]);

  if (!isConfigItemAllowed(accountConfig, loggedIn)
    || !loggedIn
    || !profile) {
    return null;
  }

  let loyaltyConfig: LoyaltyLevelColorsConfig | undefined;

  let icon;
  let progress = 0;
  if (!config?.disableLoyaltyLevel && loyalty) {
    // eslint-disable-next-line arrow-body-style
    loyaltyConfig = loyaltyLevelColors.find((levelColors) => {
      return levelColors.minLevel <= loyalty.level && levelColors.maxLevel >= loyalty.level;
    });

    let loyaltyIcon = 'star.svg';
    if (loyaltyConfig && loyaltyConfig.image) {
      loyaltyIcon = loyaltyConfig.image;
    }

    icon = {
      icon: loyaltyIcon,
      content: loyalty.level,
    };

    progress = loyalty.bar;
  }

  return (
    <LabeledItem
      label={accountConfig!.translationKey}
      onClick={toggleAccountMenu}
      dataTarget="menu-user-account"
      open={false}
      compact={compact}
      noDropdownIcon={hideDropdownArrow}
    >
      <ProgressCircle
        progress={progress}
        compact={compact}
        image={profile.avatarImageURL}
        tinyIcon={icon}
        color={loyaltyConfig?.textColor}
      />
    </LabeledItem>
  );
};

PlayerProfile.defaultProps = {
  compact: undefined,
  hideDropdownArrow: false,
};

export default PlayerProfile;
