import { useEffect, useState } from 'react';
import { ConfigsState } from '../interfaces';

const useConfigs = (configsArray: string[]): Partial<ConfigsState> => {
  const [configs, setConfigs] = useState<Partial<ConfigsState>>({});

  useEffect(() => {
    const unsubscribe = RSIConfigHandler.subscribe(
      configsArray,
      ({ data, configKey }) => {
        setConfigs((existingConfigs) => ({
          ...existingConfigs,
          [configKey]: data,
        }));
      },
    );
    return () => unsubscribe?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(configsArray)]);

  return configs;
};

export default useConfigs;
