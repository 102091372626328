import React, { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import LabeledItem from '../Wrappers/LabeledItem';
import { findConfigItem, isConfigItemAllowed } from '../../utils';
import Icon from '../SideNavigation/Icon';
import { RootState } from '../../interfaces';
import { ItemTypes } from '../../interfaces/topNavigation';
import { logger } from '../../services';

interface InvitationProps {
  compact?: boolean;
}

const StyledIcon = styled(Icon) <InvitationProps>`
  ${(props) => props.compact && css`
    margin-bottom: 4px;
  `};
`;

// eslint-disable-next-line react/function-component-definition
// @ts-ignore
const Invitation: React.FC<PropsWithChildren<InvitationProps>> = ({ compact }) => {
  const { loggedIn } = useSelector((state: RootState) => state.player);
  const { topNavigation } = useSelector((state: RootState) => state.configs);
  const {
    hasInvite,
    timeLeft,
    type,
    data,
  } = useSelector((state: RootState) => state.invite);

  const config = findConfigItem(
    topNavigation?.items || [],
    type,
  );

  const handleClick = (): void => {
    if (type === ItemTypes.Bingo) {
      (document.querySelector('.launch-swe-bingo') as HTMLElement)!.click();
    } else if (type === ItemTypes.Tournament) {
      RSIEventBus.publish(
        RSIEventBus.eventTypes.TOURNAMENTS_NAVIGATION_EVENT,
        { id: data },
      );
    } else {
      logger.error(`No handler for click with type ${type}`);
    }
  };

  if (!type) {
    return null;
  }

  return (
    isConfigItemAllowed(config, loggedIn) && hasInvite && (
      <LabeledItem
        label={timeLeft!.toString()}
        onClick={handleClick}
        open={false}
        compact={compact}
        direction="column"
        highlightedLabel
        noDropdownIcon
        noTranslate
      >
        <StyledIcon
          src={`cms/navigationAssets/${type.toLowerCase()}.svg`}
          compact={compact}
          height={compact ? 34 : 40}
          width={compact ? 34 : 40}
        />
      </LabeledItem>
    )
  );
};

Invitation.defaultProps = {
  compact: undefined,
};

export default Invitation;
