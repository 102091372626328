import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import LabeledItem from '../Wrappers/LabeledItem';
import { findConfigItem, isConfigItemAllowed } from '../../utils';
import Icon from '../SideNavigation/Icon';
import { RootState } from '../../interfaces';
import { ItemTypes } from '../../interfaces/topNavigation';
import ChatIconBadge from './ChatIconBadge';

const IconContainer = styled.div`
  margin-top: 7px;
  margin-bottom: 5px;
`;

const BackdroppedItem = styled.div`
  position: relative;
  height: 100%;
  margin-left: 15px;

  @media (min-width: 420px) {
    margin-left: 25px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -15px;
    right: -10px;
    background-color: ${(props) => props.theme.themed.background.chat};
    border-radius: 100% 0 0 0;

    @media (min-width: 420px) {
      left: -20px;
    }
  }
`;

const BackdroppedLabeledItem = styled(LabeledItem)`
  position: relative;
`;

function ChatButton() {
  const {
    loggedIn,
    profile,
  } = useSelector((state: RootState) => state.player);
  const { isGamingMode } = useSelector((state: RootState) => state.application);
  const { topNavigation } = useSelector((state: RootState) => state.configs);

  const chatConfig = findConfigItem(
    topNavigation?.items || [],
    ItemTypes.Chat,
  );

  const toggleChat = (): void => {
    RSIEventBus.publish(
      RSIEventBus.eventTypes.TOGGLE_PLAYER_CHAT_WINDOW,
      null,
      { withHistory: false, toNative: false },
    );
  };

  if (!isConfigItemAllowed(chatConfig, loggedIn, isGamingMode)
    || !loggedIn
    || !profile) {
    return null;
  }

  return (
    <BackdroppedItem>
      <BackdroppedLabeledItem
        label={chatConfig!.translationKey}
        onClick={toggleChat}
        open={false}
        compact
        noDropdownIcon
      >
        <IconContainer>
          <Icon
            src="cms/navigationAssets/chat.svg"
            height={23}
            width={27}
          />
          <ChatIconBadge />
        </IconContainer>
      </BackdroppedLabeledItem>
    </BackdroppedItem>
  );
}

export default ChatButton;
