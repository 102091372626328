import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';

import { sideNavigationActions } from '../../store/actions';
import Aside from '../rsi/Aside';
import Backdrop from '../rsi/Backdrop';
import BrandNavigation from './BrandNavigation';
import SideNavigationRow from './SideNavigationRow';
import { RootState } from '../../interfaces';
import { filterConfigItemsAllowed } from '../../utils';

const SideNavigation: React.FC = () => {
  const dispatch = useDispatch();
  const isLandscape = useSelector((state: RootState) => state.application.isLandscape);
  const showSideNavigation = useSelector((state: RootState) => state.sideNavigation.show);
  const sideNavigationConfig = useSelector((state: RootState) => state.configs.sideNavigation);
  const loggedIn = useSelector((state: RootState) => state.player.loggedIn);

  const navigationItems = filterConfigItemsAllowed(
    sideNavigationConfig?.items || [],
    loggedIn,
    undefined,
    undefined,
    isLandscape,
  );

  useEffect(() => {
    setTimeout(() => RSIEventBus.publish(RSIEventBus.eventTypes.RSI_SIDE_NAVIGATION_LOADED));
  }, []);

  return (
    <>
      {showSideNavigation && (
        <Backdrop
          visible
          level={3000}
          onClick={
            (): AnyAction => dispatch(sideNavigationActions.setVisible(false))
          }
        />
      )}

      <Aside open={showSideNavigation}>
        <BrandNavigation />

        {navigationItems.map((item, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <SideNavigationRow key={i} itemConfig={item} />
        ))}
      </Aside>
    </>
  );
};

export default SideNavigation;
