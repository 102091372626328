import { createGlobalStyle, css } from 'styled-components';
import breakpoints from '../../constants/breakpoints';

interface BetslipProps {
  hide: boolean;
}

const BetslipStyle = createGlobalStyle<BetslipProps>`

  #mod-KambiBC-betslip-container,
  #KambiBC-betslip-container {

    ${({ hide }) => hide && css`
      display: none !important;
    `}

    @media only screen and (max-width: ${breakpoints.lg}px) {

    .mod-KambiBC-betslip__outer-wrapper,
    .mod-KambiBC-betslip,
    .KambiBC-betslip__outer-wrapper,
    .KambiBC-betslip {
      width: 100% !important;
      left: 0 !important;
      right: 0 !important;
    }
  }

    .mod-KambiBC-betslip__header,
    .KambiBC-betslip__header {

      &-pin-btn {
        display: none !important;
      }
    }

    .mod-KambiBC-betslip,
    .KambiBC-betslip {
      transition: max-height .5s ease !important;
    }
  }
`;

export default BetslipStyle;
