import styled, { css } from 'styled-components';

interface ContainerProps {
  right?: boolean;
  withSpacer?: boolean;
  withScroll?: boolean;
}

const Container = styled.section<ContainerProps>`
  ${(props) => !props.right && css`
    flex: 1;
  `};
  ${(props) => props.right && css`
    margin-left: auto;
  `};
  ${(props) => props.withSpacer && css`
    width: 70px;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left-width: 1px;
    border-left-style: solid;
    border-image:
      linear-gradient(
        to bottom,
        rgba(119, 126, 135, 1),
        rgba(0, 0, 0, 0)
      ) 1 100%;
  `};
  ${(props) => props.withScroll && css`
    width: max-content;
    min-width: 100%;
  `};
  display: flex;
  justify-content: space-evenly;
`;

export default Container;
