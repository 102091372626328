import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { applicationActions } from '../store/actions';

let gameOpenSubscriber: Subscriber;
let gameCloseSubscriber: Subscriber;
let gameClientOpenSubscriber: Subscriber;
let gameClientCloseSubscriber: Subscriber;

export default (): void => {
  const dispatch = useDispatch();

  // set up listener
  const initializeGamingMode = useCallback((): void => {
    gameOpenSubscriber = RSIEventBus.subscribe(
      RSIEventBus.eventTypes.OPEN_GAME,
      () => {
        dispatch(applicationActions.setGamingMode(true));
      },
    );
    gameClientOpenSubscriber = RSIEventBus.subscribe(
      RSIEventBus.eventTypes.OPEN_GAME_CLIENT,
      () => {
        dispatch(applicationActions.setGamingMode(true));
      },
    );
    gameCloseSubscriber = RSIEventBus.subscribe(
      RSIEventBus.eventTypes.CLOSE_GAME,
      () => {
        dispatch(applicationActions.setGamingMode(false));
      },
    );
    gameClientCloseSubscriber = RSIEventBus.subscribe(
      RSIEventBus.eventTypes.CLOSE_GAME_CLIENT,
      () => {
        dispatch(applicationActions.setGamingMode(false));
      },
    );
  }, [dispatch]);

  useEffect(() => {
    initializeGamingMode();
    return () => {
      gameOpenSubscriber.unsubscribe();
      gameCloseSubscriber.unsubscribe();
      gameClientOpenSubscriber.unsubscribe();
      gameClientCloseSubscriber.unsubscribe();
    };
  }, [initializeGamingMode]);
};
