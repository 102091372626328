import { Keyframes, keyframes } from 'styled-components';

export default (props: any): Keyframes => keyframes`

  0% {
    background-color: transparent;
  }

  20%,
  60% {
    background-color: ${props.theme.plain.background.badge};
  }
`;
