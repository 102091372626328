import styled from 'styled-components';

const Navigation = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.plain.background.primary};
  z-index: 3000;
  display: flex;
  align-items: flex-start;
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, .15);
`;

export default Navigation;
