import * as React from 'react';
import {
  useEffect, useState, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';

import styled from 'styled-components';
import {
  isClient, isConfigItemAllowed, isUserStatusAllowed,
} from '../../../utils';

import Icon from '../../SideNavigation/Icon';
import BrandNavigation from './BrandNavigation';
import ProductNavigation, { IconContainer } from '../Desktop/ProductNavigation';

import { HeaderLeftContainer } from '../../rsi/Header';
import { RootState } from '../../../interfaces';
import { ConfigItem, ItemTypes, LinkItem } from '../../../interfaces/topNavigation';
import { sideNavigationActions } from '../../../store/actions';
import Breakpoints from '../../../constants/breakpoints';
import { MobileHeaderLink } from './Header';
import Translate from '../../Translate';

export const SidenavToggleIcon = styled.div`
  margin-right: ${window.innerWidth >= Breakpoints.sm ? '5px' : '8px'};
`;

/**
 * TODO
 * should probably use LabeledItem instead of this
 * but too much in a hurry to refactor
 */
export const IconLink: React.FC<{ item: LinkItem }> = ({
  item,
}) => (
  <MobileHeaderLink href={item.link}>
    {item.customImage && (
      <IconContainer
        src={`cms/navigationAssets/${item.customImage}`}
        alt={item.customImage}
        maxHeight={25}
        maxWidth={25}
        className={item.conditional.hideLabel ? 'label-hidden' : ''}
      />
    )}
    {!item.conditional.hideLabel && (
      <Translate id={item.translationKey} />
    )}
  </MobileHeaderLink>
);

const HeaderLeft: React.FC = () => {
  const dispatch = useDispatch();
  const {
    isMobilePortrait,
  } = useSelector((state: RootState) => state.application);
  const dual = useSelector((state: RootState) => state.logo.dual);
  const [showProducts, setShowProducts] = useState<boolean>(false);
  const { loggedIn, balance } = useSelector((state: RootState) => state.player);
  const topNavigation = useSelector((state: RootState) => state.configs.topNavigation);
  const setSidenavVisible = (): AnyAction => dispatch(sideNavigationActions.setVisible(true));

  const populateShowProducts = useCallback(() => {
    setShowProducts(!isMobilePortrait && window.innerWidth >= (dual ? 660 : 620));
  }, [dual, isMobilePortrait]);

  useEffect(() => {
    if (isClient) {
      populateShowProducts();

      window.addEventListener('resize', populateShowProducts);
    }

    return () => {
      if (isClient) {
        window.removeEventListener('resize', populateShowProducts);
      }
    };
  }, [isMobilePortrait, populateShowProducts]);

  function typeCheck<T extends ConfigItem>(item: ConfigItem, type: ItemTypes): item is T {
    return item.type === type;
  }

  const renderAlwaysVisibleItems = (): (JSX.Element | null)[] => {
    const items: ConfigItem[] = topNavigation?.items?.filter(
      (item: ConfigItem): boolean => !!item.conditional.alwaysVisibleMobile,
    ) || [];

    return items.map((item: ConfigItem) => {
      if (item.type === ItemTypes.Burger) {
        return (isConfigItemAllowed(item, loggedIn) && balance && (
          <SidenavToggleIcon>
            <Icon
              src="cms/navigationAssets/burger-menu.svg"
              height={21}
              width={21}
              iconClick={setSidenavVisible}
            />
          </SidenavToggleIcon>
        )) || null;
      }
      if (typeCheck<LinkItem>(item, ItemTypes.Link) && item.type === ItemTypes.Link) {
        return (isUserStatusAllowed(item, loggedIn) && (
          <IconLink item={item} />
        )) || null;
      }
      if (item.type === ItemTypes.Logo) {
        return <BrandNavigation />;
      }
      return null;
    });
  };

  return (
    <HeaderLeftContainer>
      {renderAlwaysVisibleItems()}

      {showProducts && (
        <ProductNavigation />
      )}
    </HeaderLeftContainer>
  );
};

export default HeaderLeft;
