import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { applicationActions, logoActions } from '../store/actions';
import { LogoFiles, Logos, RootState } from '../interfaces';

export default (): void => {
  const dispatch = useDispatch();
  const { rsiApiLoaded, viewType } = useSelector((state: RootState) => state.application);
  const logoFiles = useSelector((state: RootState) => state.configs.config?.logoFiles || null);

  const getPageType = (): keyof Logos => {
    const cageInfo = rsiApi.getModule('components/CageInfo');
    return cageInfo.getCurrentPageType();
  };

  const getLogoByPageType = (files: LogoFiles | null, dual?: boolean): string | null => {
    if (!files) {
      return null;
    }

    const logoSrcMap = dual ? files.dual : files.primary;
    if (logoSrcMap) {
      return logoSrcMap[getPageType()] || logoSrcMap.DEFAULT || null;
    }

    return null;
  };

  const onPageTypeChange = (files: LogoFiles | null): void => {
    dispatch(logoActions.setLogoPaths({
      primary: getLogoByPageType(files) || 'logo.svg',
      dual: getLogoByPageType(files, true),
    }));
  };

  const initializePageTypeChange = (): void => {
    dispatch(applicationActions.setViewType(getPageType()));

    rsiApi.on(rsiApi.getEvent('CAGE_INFO_VIEW_TYPE'), () => {
      dispatch(applicationActions.setViewType(getPageType()));
    });
  };

  useEffect(() => {
    if (!rsiApiLoaded || !logoFiles) {
      return;
    }

    if (!viewType) {
      initializePageTypeChange();
    }
    // eslint-disable-next-line
  }, [rsiApiLoaded, logoFiles]);

  useEffect(() => {
    if (!rsiApiLoaded || !viewType) {
      return;
    }
    onPageTypeChange(logoFiles);
    // eslint-disable-next-line
  }, [rsiApiLoaded, viewType, logoFiles]);
};
