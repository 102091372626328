import { AnyAction } from 'redux';
import { configActionTypes } from '../actionTypes';
import { ConfigsState } from '../../interfaces';

const initialState: ConfigsState = {
  bottomNavigation: null,
  topNavigation: null,
  sideNavigation: null,
  theme: null,
  compactNavigation: null,
  siteSelect: null,
  loyaltyLevelColors: [],
  tournamentsConfig: null,
  config: null,
  gameWindow: null,
  cageConf: null,
};

/**
 * In order to fetch configs, you should use the `useConfigs` hook available in `src/lib/hooks`/
 * TODO: SPORT-2926 Deprecate store configs
 */
export default (
  state = initialState,
  { type, payload }: AnyAction = { type: configActionTypes.DEFAULT },
) => {
  switch (type) {
    case configActionTypes.GET_API_CONFIG_SUCCESS:
      return {
        ...state,
        api: payload,
      };
    case configActionTypes.GET_LANGUAGE_CONFIG_SUCCESS:
      return {
        ...state,
        language: payload,
      };
    case configActionTypes.GET_TIME_CONFIG_SUCCESS:
      return {
        ...state,
        cageTime: payload,
      };
    case configActionTypes.GET_NAVIGATION_CONFIG_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case configActionTypes.GET_LOYALTY_LEVELS_CONFIG_SUCCESS:
      return {
        ...state,
        loyaltyLevelColors: payload,
      };
    case configActionTypes.GET_SITE_SELECT_CONFIG_SUCCESS:
      return {
        ...state,
        siteSelect: payload,
      };
    case configActionTypes.GET_TOURNAMENTS_CONFIG_SUCCESS:
      return {
        ...state,
        tournamentsConfig: payload,
      };
    default:
      return state;
  }
};
