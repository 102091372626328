export default {
  SET_PLAYER: 'player/SET_PLAYER',
  SET_PLAYER_PROFILE: 'player/SET_PLAYER_PROFILE',
  SET_PLAYER_AVATAR: 'player/SET_PLAYER_AVATAR',
  SET_PLAYER_LOYALTY_BAR: 'player/SET_PLAYER_LOYALTY_BAR',
  SET_PLAYER_BONUS_BAR: 'player/SET_PLAYER_BONUS_BAR',
  SET_PLAYER_BALANCE: 'player/SET_PLAYER_BALANCE',
  SET_PLAYER_NEW_BALANCE: 'player/SET_PLAYER_NEW_BALANCE',
  SET_PLAYER_CUSTOM_TOKENS: 'player/SET_PLAYER_CUSTOM_TOKENS',
  SET_PLAYER_LOYALTY_TOKENS: 'player/SET_PLAYER_LOYALTY_TOKENS',
};
