import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  BaseSiteSelectItem, GroupSiteItem, RootState, SiteSelectItem,
} from '../../../interfaces';
import { ItemTypes, ProductItem } from '../../../interfaces/topNavigation';
import { filterConfigItemsAllowed, siteSelect } from '../../../utils';
import Backdrop from '../../rsi/Backdrop';
import Icon from '../../SideNavigation/Icon';
import DropdownLabel from '../../Wrappers/DropdownLabel';

import {
  BrandHeader,
  BrandItemBrand,
  BrandItemButton,
  BrandItemGroup,
  BrandItemLink,
  BrandSelect,
  CurrentBrand,
  CurrentBrandIcon,
  CurrentBrandIconLink,
  DualBrandSeparator,
} from './Brand';
import Translate from '../../Translate';

const BrandNavigation: React.FC = () => {
  const [brandsOpen, setBrandsOpen] = useState(false);

  const brands = useSelector((state: RootState) => state.configs.siteSelect?.items || []);
  const cookieDomain = useSelector(
    (state: RootState) => state.configs.siteSelect?.cookieDomain || null,
  );
  const { topNavigation } = useSelector((state: RootState) => state.configs);
  const bottomNavVisible = useSelector((state: RootState) => state.bottomNavigation.visible);
  const currentSite = brands.find((brand) => {
    if (brand.currentSite) {
      return true;
    }

    if (brand.groupSites) {
      return brand.groupSites.find((site) => site.currentSite);
    }

    return false;
  });

  const {
    isMobilePortrait,
    isGamingMode,
  } = useSelector((state: RootState) => state.application);
  const config = useSelector((state: RootState) => state.configs.config);
  const loggedIn = useSelector((state: RootState) => state.player.loggedIn);
  const { primary, dual } = useSelector((state: RootState) => state.logo);
  const dualBrandingEnabled = !(isMobilePortrait && isGamingMode) && dual;
  const logoLink = (filterConfigItemsAllowed(
    topNavigation?.items || [],
    loggedIn,
    isGamingMode,
  ).find((item): item is ProductItem => (
    item.type === ItemTypes.Casino || item.type === ItemTypes.Sportsbook
    || item.type === ItemTypes.Poker
  )))?.link || null;

  const onSiteSelect = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    skinCode: string,
    isActive: boolean,
  ) => {
    if (isActive) {
      event.preventDefault();
      setBrandsOpen(false);
    } else {
      siteSelect(skinCode, cookieDomain!);
    }
  };

  const getBrandIconContent = () => (
    <>
      <Icon
        src={primary}
        maxHeight={32}
        maxWidth={dualBrandingEnabled ? 60 : 80}
        xsScreenMaxWidth={dualBrandingEnabled ? 50 : null}
        height="100%"
        width="100%"
      />
      {dualBrandingEnabled && (
        <>
          <DualBrandSeparator />
          <Icon
            src={dual as string}
            maxHeight={32}
            maxWidth={60}
            xsScreenMaxWidth={30}
            height="100%"
            width="100%"
          />
        </>
      )}
    </>
  );

  const shouldShowSiteSelect = brands.length > 1 && (!config?.siteSelection?.enabledPlatforms
    || config?.siteSelection?.enabledPlatforms?.includes(RSIUtils.detector.detailedDeviceType));

  const renderBrandIcon = () => {
    if (shouldShowSiteSelect) {
      return (
        <CurrentBrandIcon
          onClick={() => setBrandsOpen(!brandsOpen)}
          data-testid="brand-navigation"
          data-test-type="brand-navigation"
        >
          {getBrandIconContent()}
        </CurrentBrandIcon>
      );
    }
    return (
      <CurrentBrandIconLink
        href={logoLink || ''}
        data-testid="brand-navigation"
        data-test-type="brand-product-navigation-link"
      >
        {getBrandIconContent()}
      </CurrentBrandIconLink>
    );
  };

  const getBrandText = useCallback((brand: BaseSiteSelectItem) => (
    <>
      <Translate id={`SITE_SELECT_BTN_${brand.stateCode}`} />

      {brand.brandName && brand.brandName.trim() && (
        <BrandItemBrand
          active={brand.currentSite}
        >
          {`❘ ${brand.brandName.trim()}`}
        </BrandItemBrand>
      )}
    </>
  ), []);

  const isSiteSelectItemGuard = (
    siteSelectItem: BaseSiteSelectItem,
  ): siteSelectItem is SiteSelectItem => (
    siteSelectItem as SiteSelectItem
  ).groupSites !== undefined;

  const getBrandLink = (siteSelectItem: SiteSelectItem | GroupSiteItem) => (
    <BrandItemLink
      indentLevel={!isSiteSelectItemGuard(siteSelectItem) ? siteSelectItem.indentLevel : 0}
      onClick={(event) => onSiteSelect(event, siteSelectItem.skinCode, siteSelectItem.currentSite)}
      key={siteSelectItem.skinCode}
      active={siteSelectItem.currentSite}
      href={`${siteSelectItem.siteUrl}?setSite=${siteSelectItem.skinCode}`}
      group={false}
      data-testid={`type-brand-link-${siteSelectItem.skinCode}`}
    >
      {getBrandText(siteSelectItem)}
    </BrandItemLink>
  );

  return (
    <>
      <BrandHeader>
        {renderBrandIcon()}

        <CurrentBrand
          onClick={() => setBrandsOpen(!shouldShowSiteSelect && !brandsOpen)}
          data-testid="brand-navigation"
          data-test-type={shouldShowSiteSelect ? 'brand-navigation-button' : 'brand-name'}
        >
          {currentSite && shouldShowSiteSelect && (
            <DropdownLabel
              open={brandsOpen}
              noDropdownIcon={
                (topNavigation && topNavigation.showMobileDropdownArrows === false)
                || !shouldShowSiteSelect
              }
            >
              <Translate id={`SITE_SELECT_BTN_${currentSite.stateCode}`} />
            </DropdownLabel>
          )}
        </CurrentBrand>
      </BrandHeader>

      {shouldShowSiteSelect && brandsOpen && (
        <>
          <Backdrop
            level={1}
            onClick={() => setBrandsOpen(false)}
            data-testid="brand-navigation-backdrop"
          />
          <BrandSelect
            hasBottomNav={bottomNavVisible}
            data-testid="brands-list"
          >
            {brands.map((brand) => {
              if (brand.groupSites.length) {
                return (
                  <BrandItemGroup
                    data-testid={`type-group-${brand.stateCode}`}
                  >
                    <BrandItemButton
                      key={brand.skinCode}
                      active={brand.currentSite}
                      group
                      data-testid="type-group-title"
                    >
                      {getBrandText(brand)}
                    </BrandItemButton>

                    {brand.groupSites.map((site) => (
                      getBrandLink(site)
                    ))}
                  </BrandItemGroup>
                );
              }

              return getBrandLink(brand);
            })}
          </BrandSelect>
        </>
      )}
    </>
  );
};

export default BrandNavigation;
